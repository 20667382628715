import { MaybeStyledCSS } from '@belong/types';
import { isFocused } from '@belong/themes';
import styled, { css } from 'styled-components';
import ButtonUnstyled from '../ButtonUnstyled';

export const Button = styled(ButtonUnstyled)`
  &:focus {
    ${isFocused()};
    outline-offset: 0;
  }

  ${(props): MaybeStyledCSS =>
    props.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `};
`;
