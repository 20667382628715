import { Entry } from '@belong/types';

import { CONTENT_LOOKUP_KEY } from './constants';

export const isCampaignEntry = (entry: Entry<unknown>, campaignCode: string): boolean => {
  const data = entry.metadata;

  // is default of no tags
  if (!data?.tags || !data?.tags?.length) {
    return false;
  }
  // is camapign if includes campaign flag and matching code
  const result = data.tags.find(tag => {
    const id = tag.sys.id.toUpperCase();
    return id.includes(CONTENT_LOOKUP_KEY) && id.replace(CONTENT_LOOKUP_KEY, '') === campaignCode.toUpperCase();
  });

  return !!result;
};
