import slugify from 'slugify';
import { ArticleGroupLayout } from '@belong/ui-components/components/contentBlocks/ArticleGroup';
import { IArticleTile, IArticleGroup } from '@belong/ui-components';
import { IGenericCategory, ILink, IContentfulSection, Entry } from '@belong/types';
import { FImage, FLink } from '@belong/contentful';

export interface ISectionArticleGroup extends IContentfulSection<'sectionArticleGroup'>, IArticleGroup {
  title: string;
  links: ILink[];
  supportCategory?: IGenericCategory;
}

function FArticleMapper(article: Entry<any>, supportCategory?: IGenericCategory): IArticleTile | null {
  if (article.sys.contentType.sys.id === 'page') {
    // an article could be a blog or a regular page so return the correct model
    const isBlogArticle = article.fields.blogArticle !== undefined;
    return isBlogArticle ? FBlogArticle(article) : FPage(article);
  }
  return FSupportArticle(article, supportCategory);
}

function FSupportArticle(data: Entry<any>, supportCategory?: IGenericCategory): IArticleTile {
  const { title, categories, featuredImage, metaPageSlug: url } = data.fields;

  return {
    contentId: data.sys.id,
    category: supportCategory || categories[0],
    title,
    image: featuredImage && FImage(featuredImage),
    url
  };
}

function FBlogArticle(data: Entry<any>): IArticleTile {
  const { fields } = data;

  const articleData = fields.page ?? fields.blogArticle;

  return {
    contentId: data.sys.id,
    category: { id: '', label: '' },
    title: fields.pageName ?? fields.pageTitle,
    image: FImage(articleData.fields.coverImage), // cover image is required
    url: fields.pageUrl.fields.url
  };
}

function FPage(data: Entry<any>): IArticleTile {
  const { pageName, featuredImage } = data.fields;

  return {
    category: { id: '', label: '' },
    title: pageName,
    image: featuredImage && FImage(featuredImage),
    url: data.fields.pageUrl.fields.url
  };
}

function FSectionArticleGroup(data: Entry<any>): ISectionArticleGroup {
  const { identifier, supportCategory, sectionStyles, title, layout: layoutBool, articles, links = [] } = data.fields;
  const layout = layoutBool ? ArticleGroupLayout.LEFT : ArticleGroupLayout.RIGHT;

  return {
    id: data.sys.id,
    identifier: slugify(identifier),
    contentType: 'sectionArticleGroup',
    supportCategory,
    layout,
    title,
    sectionStyles,
    articles: articles.map(article => FArticleMapper(article, supportCategory)).filter(Boolean),
    links: links.map(FLink)
  };
}

export default FSectionArticleGroup;
