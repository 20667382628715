import { FunctionComponent } from 'react';

import { FONT_COLOURS } from '@belong/themes';

import { getResponsiveSrc } from '../../../helpers/image';
import { Copy } from '../../styles/Typography/Copy';
import { PictureContainer } from '../../media/PictureContainer';
import ArticleTileSkeleton from './ArticleTile.skeleton';
import * as Styles from './ArticleTile.styles';
import { IArticleTile } from './types';

export const TEST_ID = {
  ARTICLE_TILE: 'article-tile',
  CATEGORY: 'category',
  TITLE: 'title'
};

/**
 * Button Tile used to display the Category, Title and Image of a Help and Support Article and allow the user to
 * link through to the article content.
 *
 * Has three states: Hero, Slim and Loading
 */
export const ArticleTile: FunctionComponent<IArticleTile> = (props: IArticleTile) => {
  const { contentId, category, title, image, url, isLoading = false, isHero = false } = props;
  const headingLevel = props.headingLevel || 'h3';
  const imageSource = isHero ? Styles.IMAGE_SIZE.hero : Styles.IMAGE_SIZE.slim;
  const imgSrc = image && getResponsiveSrc(image, imageSource, { fit: 'fill', f: 'face' });
  const shouldRenderAsHero = isHero && !!image;

  if (isLoading) {
    return <ArticleTileSkeleton isHero={isHero} />;
  }

  return (
    <Styles.Button data-testid={TEST_ID.ARTICLE_TILE} data-contentid={contentId} href={url} isHero={shouldRenderAsHero}>
      <Styles.Container isHero={shouldRenderAsHero}>
        <Styles.Content>
          <Copy data-testid={TEST_ID.CATEGORY} variant="small" hasColor={FONT_COLOURS.FINE_PRINT} isBold>
            {category.label}
          </Copy>
          <Copy as={headingLevel} data-testid={TEST_ID.TITLE} hasColor={FONT_COLOURS.STRONG} isBold>
            {title}
          </Copy>
        </Styles.Content>
        {image && (
          <Styles.ImageWrapper contentType={image.contentType}>
            <PictureContainer {...image} src={imgSrc} />
          </Styles.ImageWrapper>
        )}
      </Styles.Container>
    </Styles.Button>
  );
};

ArticleTile.displayName = 'ArticleTile';

export default ArticleTile;
