import { Entry } from 'contentful';

import { FLink } from './elements/elementLink.factory';
import { FSystemIcon } from './elements/elementSystemIcon.factory';
import { FElementEmbeddedInformationModal } from './elements/elementEmbeddedInformationModal.factory';
import { FEmail, FGenericInput, FPassword } from './inputs';
import {
  FMoleculeAsset,
  FMoleculeAlert,
  FMoleculeRichText,
  FMoleculeText,
  FLinkList,
  FLinkListItem,
  FMoleculeMediaCaptionBlock,
  FMoleculeTileHeader
} from './molecules';
import { FSectionInfo } from './sections';
import { parseFlexibleContent } from './parseFlexibleContent';
import { FGenericInputGroup } from './inputs/genericInputGroup.factory';
import { FAccordionRow } from './accordionRow.factory';

export const SCREEN_CMS_FACTORY_MAP = new Map([
  ['elementAccordionRow', { factory: FAccordionRow }],
  ['elementEmbeddedInformationModal', { factory: FElementEmbeddedInformationModal }],
  ['elementLink', { factory: FLink }],
  ['elementSystemIcon', { factory: FSystemIcon }],
  ['inputEmail', { factory: FEmail }],
  ['inputGeneric', { factory: FGenericInput }],
  ['inputGenericGroup', { factory: FGenericInputGroup }],
  ['inputPassword', { factory: FPassword }],
  ['moleculeAlert', { factory: FMoleculeAlert }],
  ['moleculeAsset', { factory: FMoleculeAsset }],
  ['moleculeRichText', { factory: FMoleculeRichText }],
  ['moleculeLinkList', { factory: FLinkList }],
  ['moleculeLinkListItem', { factory: FLinkListItem }],
  ['moleculeMediaCaptionBlock', { factory: FMoleculeMediaCaptionBlock }],
  ['moleculeText', { factory: FMoleculeText }],
  ['moleculeTileHeader', { factory: FMoleculeTileHeader }],
  ['sectionInfo', { factory: FSectionInfo }]
]);

export const FGenericScreen = (data: Entry<any>): any => {
  const { content } = data.fields;

  return parseFlexibleContent(content, SCREEN_CMS_FACTORY_MAP);
};
