import dynamic from 'next/dynamic';

export const sectionAccordionList = dynamic(
  () => /* webpackChunkName: "sectionAccordionList" */ import('./sectionAccordionList/view')
);
export const sectionActionStrip = dynamic(
  () => /* webpackChunkName: "sectionActionStrip" */ import('./sectionActionStrip/view')
);
export const sectionActionTiles = dynamic(
  () => /* webpackChunkName: "sectionActionTiles" */ import('./sectionActionTiles/view')
);
export const sectionArticleGroup = dynamic(
  () => /* webpackChunkName: "sectionArticleGroup" */ import('./sectionArticleGroup/view')
);
export const sectionArticleHeader = dynamic(
  () => /* webpackChunkName: "sectionArticleHeader" */ import('./sectionArticleHeader/view')
);
export const sectionBanner = dynamic(() => /* webpackChunkName: "sectionBanner" */ import('./sectionBanner/view'));
export const sectionCategoryFilter = dynamic(
  () => /* webpackChunkName: "sectionCategoryFilter" */ import('./sectionCategoryFilter/view')
);
export const sectionCountryList = dynamic(
  () => /* webpackChunkName: "sectionCountryList" */ import('./countryList/view')
);
export const sectionCoverageMap = dynamic(
  () => /* webpackChunkName: "sectionCoverageMap" */ import('./sectionCoverageMap/view')
);
export const sectionFeatureTiles = dynamic(
  () => /* webpackChunkName: "sectionFeatureTiles" */ import('./sectionFeatureTiles/view')
);
export const sectionImageGrid = dynamic(
  () => /* webpackChunkName: "sectionImageGrid" */ import('./sectionImageGrid/view')
);
export const sectionImageStrip = dynamic(
  () => /* webpackChunkName: "sectionImageStrip" */ import('./sectionImageStrip/view')
);
export const sectionInfo = dynamic(() => /* webpackChunkName: "sectionInfo" */ import('./sectionInfo/view'));
export const sectionIntroductionText = dynamic(
  () => /* webpackChunkName: "sectionIntroductionText" */ import('./sectionIntroductionText/view')
);
export const sectionLinkList = dynamic(
  () => /* webpackChunkName: "sectionLinkList" */ import('./moleculeLinkList/view')
);
export const sectionPageError = dynamic(
  () => /* webpackChunkName: "sectionPageError" */ import('./sectionPageError/view')
);
export const sectionPageTitle = dynamic(
  () => /* webpackChunkName: "sectionPageTitle" */ import('./sectionPageTitle/view')
);
export const sectionPlanSummary = dynamic(
  () => /* webpackChunkName: "sectionPlanSummary" */ import('./sectionPlanSummary/view')
);
export const sectionPrimaryBanner = dynamic(
  () => /* webpackChunkName: "sectionPrimaryBanner" */ import('./sectionPrimaryBanner/view')
);
export const sectionRichText = dynamic(
  () => /* webpackChunkName: "sectionRichText" */ import('./sectionRichText/view')
);
export const sectionSecondaryBanner = dynamic(
  () => /* webpackChunkName: "sectionSecondaryBanner" */ import('./sectionSecondaryBanner/view')
);
export const sectionTextImageBlock = dynamic(
  () => /* webpackChunkName: "sectionTextImageBlock" */ import('./sectionTextImageBlock/view')
);
export const sectionAbnForm = dynamic(() => /* webpackChunkName: "sectionAbnForm" */ import('./sectionAbnForm/view'));

export const sectionRichTextColumns = dynamic(
  () => /* webpackChunkName: "sectionRichTextColumns" */ import('./sectionRichTextColumns/view')
);
