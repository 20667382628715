import { IArticleTile } from '../ArticleTile';

export enum ArticleGroupLayout {
  LEFT = 'left',
  RIGHT = 'right'
}

export interface IArticleGroup {
  articles: Omit<IArticleTile, 'layout, isHero'>[];
  isLoading?: boolean;
  layout?: ArticleGroupLayout;
}
