import { Entry } from '@belong/types';
import { CONFIG } from '../target-config';
import { IAdobeTargetActivityContent, IAdobeTargetExperienceContent } from './types';

export const FAdobeTargetActivity = (data: Entry<any>): IAdobeTargetActivityContent => {
  const { identifier, mBoxName, activityId, experiences } = data.fields;

  return {
    identifier,
    contentType: 'adobeTargetActivity',
    mBoxName,
    activityId,
    experiences: experiences.map((experience: Entry<any>) => FAdobeTargetExperience(experience))
  };
};

export const FAdobeTargetExperience = (data: Entry<any>): IAdobeTargetExperienceContent => {
  const { identifier, experienceName, experienceContent } = data.fields;

  // check for adobeGenericBlock's in the experienceContent
  const updatedExperienceSection = replaceGenericExperienceSection(experienceContent);

  return {
    identifier,
    contentType: 'adobeTargetExperience',
    experienceName,
    experienceContent: updatedExperienceSection
  };
};

/*
  Replaces the content type id of the adobeGenericBlock with the required local section type 
*/
const replaceGenericExperienceSection = (experienceContent): Entry<any>[] => {
  return experienceContent.map((section: Entry<any>) => {
    if (section.sys.contentType.sys.id === CONFIG.contentfulGenericModelId) {
      // find the content type id from the section fields and updating the sys.contentType.sys.id
      const updatedSection = {
        ...section,
        sys: {
          ...section.sys,
          contentType: {
            ...section.sys.contentType,
            sys: {
              // this is the real change, merging the sys object with the content type id
              // everything above is just to keep the original sys object
              ...section.sys.contentType.sys,
              id: section.fields.sectionContentType
            }
          }
        }
      };

      return updatedSection;
    }
    return section;
  });
};
