import type { IMoleculeCallout } from '@belong/types';
import { FRichText } from '../richText.factory';
import { FImage } from '../image.factory';
import { FSystemIcon } from '../elements/elementSystemIcon.factory';

export const FMoleculeCallout = (data: any): IMoleculeCallout => {
  const { colour, icon, title, description, backgroundImage, available, variant } = data.fields;
  return {
    id: data.sys.id,
    contentType: data.sys.contentType.sys.id,
    colour,
    icon: icon && FSystemIcon(icon),
    title: title && FRichText(title),
    description: description && FRichText(description),
    backgroundImage: backgroundImage && FImage(backgroundImage),
    available,
    variant: variant?.toLowerCase()
  };
};
