import { GetServerSidePropsContext, NextPageContext } from 'next';
import { getNbnPromoCookie } from '@belong/cookies';
import { logger } from '@belong/logging';

export const checkForPromo = (ctx?: NextPageContext | GetServerSidePropsContext): string => {
  let nbnPromoCookie;
  try {
    nbnPromoCookie = getNbnPromoCookie(ctx as NextPageContext);
  } catch {
    logger.error('Read and parse cookie error');
  }

  // Currently this will take the implicit promo over the explicit. However we are currently not stacking, when we do, this will need to be revisted.
  return nbnPromoCookie?.utmCampaign || nbnPromoCookie?.promo || '';
};
