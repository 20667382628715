import React from 'react';
import ArticleTileSkeleton from '../ArticleTile/ArticleTile.skeleton';
import * as Styles from './ArticleGroup.styles';

export const Skeleton = () => (
  <Styles.ListContainer>
    <ArticleTileSkeleton />
    <ArticleTileSkeleton />
    <ArticleTileSkeleton />
  </Styles.ListContainer>
);

Skeleton.displayName = 'Skeleton';
