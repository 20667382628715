export interface IErrorEmail {
  required: string;
  invalidEmail: string;
}

export interface IEmail {
  id: string;
  name: 'email';
  contentType: 'inputEmail';
  label: string;
  description?: string;
  errorMessage: IErrorEmail;
}

export const FEmail = (data: any): IEmail => {
  return {
    id: data.sys.id,
    name: 'email',
    contentType: data.sys.contentType.sys.id,
    label: data.fields.label,
    description: data.fields.description,
    errorMessage: {
      required: data.fields.errorMessageRequired,
      invalidEmail: data.fields.errorMessageInvalidEmail
    }
  };
};
