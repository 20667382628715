import React from 'react';
import styled, { css } from 'styled-components';

import { IHeading, ISpacingProps, ShadowsKey } from '@belong/types';
import { BORDER_RADIUS, COLOURS, spacingMixin } from '@belong/themes';

import { Heading } from '../../styles/Typography/Heading';
import UICard from '../../styles/UICard';

export interface ICardV2 extends ISpacingProps {
  children?: React.ReactNode;
  className?: string;
  decoration?: React.ReactNode;
  hasBorder?: boolean /* border is required for selected state */;
  selected?: boolean;
  shadowVariant?: ShadowsKey;
}

const CardV2: React.FC<ICardV2> = ({ children, decoration, hasPadding, ...otherProps }: ICardV2) => (
  <CardBase data-testid="card-v2" {...otherProps} hasShadow>
    {decoration}
    <CardInner hasPadding={hasPadding}>{children}</CardInner>
  </CardBase>
);

export const CardBase = styled(UICard)<Pick<ICardV2, 'hasBorder' | 'selected'> & ISpacingProps>`
  ${spacingMixin};
  position: relative;

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border: 2px solid transparent;
    `};

  ${({ selected, hasBorder }): any =>
    selected &&
    hasBorder &&
    css`
      border: 2px solid ${COLOURS.BELONG_BLUE};
      background: ${COLOURS.FUNCTIONAL_LIGHT_BLUE};
    `}
`;

export const CardInner = styled.div.attrs((props: ISpacingProps) => ({
  ...props,
  hasPadding: props.hasPadding || { xs: 1.6, md: 2.4 }
}))`
  ${spacingMixin}
`;

export const CardHeading = styled(Heading).attrs((props: IHeading) => ({
  ...props,
  alignment: props.alignment || 'left',
  hasPadding: props.hasPadding || '1.6rem 1.6rem 0',
  variant: 'small'
}))`
  ${spacingMixin};
  border-top-left-radius: ${BORDER_RADIUS.CARD};
  border-top-right-radius: ${BORDER_RADIUS.CARD};
`;

CardV2.displayName = 'CardV2';
export default CardV2;
