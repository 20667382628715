import React, { FunctionComponent } from 'react';
import { BUTTON_TEST_ID } from '../../../helpers/testIds';
import withLink from '../../hocs/withLink/withLink';
import { IGenericButton } from './GenericButton.types';
import { Button } from './GenericButton.styles';

/**
 * Generic Use Button for wrapping UI Elements to provide link functionality
 * Adds only generic styles - makes no assumptions about wrapped content
 */
const GenericButton: FunctionComponent<IGenericButton> = ({ children, ...props }) => {
  const testId = props['data-testid'] || BUTTON_TEST_ID.GENERIC;

  return (
    <Button data-testid={testId} {...props}>
      {children}
    </Button>
  );
};

GenericButton.displayName = 'GenericButton';
export default withLink(GenericButton);
