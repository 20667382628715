import slugify from 'slugify';
import { IContentfulSection, IPageTitle } from '@belong/types';
import { FImage } from '../image.factory';

export interface ISectionPageTitle extends IContentfulSection<'sectionPageTitle'>, IPageTitle {}

export const FSectionPageTitle = (data: any): ISectionPageTitle => {
  const { identifier, heading, backgroundColor, backgroundImage, variant, hasVariant } = data.fields;

  return {
    backgroundColor,
    backgroundImage: backgroundImage && FImage(backgroundImage),
    contentType: 'sectionPageTitle',
    heading,
    id: data.sys.id,
    identifier: slugify(identifier),
    variant: hasVariant || variant
  };
};
