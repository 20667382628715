import React, { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

import useAnalyticsEvents from './hook';

interface IProps {
  value: Record<string, any>;
}

export const AnalyticsContext: React.FC<IProps> = ({ value }: IProps) => {
  const valueRef = useRef<IProps['value']>(value);
  const analyticsEvents = useAnalyticsEvents();

  // We only want to update context if the object details have actually changed
  if (!isEqual(value, valueRef.current)) {
    valueRef.current = value;
  }

  useEffect(() => {
    analyticsEvents.addContext(value);

    return () => analyticsEvents.removeContext(value);
  }, [valueRef.current, analyticsEvents]);

  return null;
};

AnalyticsContext.displayName = 'AnalyticsContext';

export default AnalyticsContext;
