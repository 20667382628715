import type { ALERT_LEVELS, Document, Entry, IGraphAlert, IMoleculeAlert, IRichText } from '@belong/types';
import { FRichText } from '../richText.factory';
import { FLink } from '../elements/elementLink.factory';
import { structureResponse } from '../structureResponse';

export interface IEntryMoleculeAlert {
  key?: string;
  code?: string;
  inputErrorMessage?: string;
  inputName?: string;
  level: ALERT_LEVELS;
  link?: Entry<any>;
  message: Document | IRichText;
}

const isRichText = (message: IEntryMoleculeAlert['message']): message is IRichText => 'html' in message;

export const FMoleculeAlert = (data: Entry<IEntryMoleculeAlert> | IGraphAlert): IMoleculeAlert => {
  const { sys, fields } = structureResponse(data) as Entry<IEntryMoleculeAlert>;
  // We want to exclude the `key` from the output
  const { key, code, link, message, ...otherFields } = fields;

  return {
    id: sys.id,
    ...otherFields,
    code: code ?? '',
    link: link && FLink(link),
    message: isRichText(message) ? message : FRichText(message)
  };
};
