import { logger } from '@belong/logging';

/* Returns the Adobe Experience Cloud Visitor ID (aka MID, aka ECID) from the AMCV cookie.
   We really want to send this value to Target IF it is available. IF it is not, then it's not the end of the world.
   Sending this value helps stitch the A/B test results back to the user's profile in Adobe Analytics.
   The adobeCookieName is hardcoded for now, but should be an environment variable.
   The adobeCookieValue structure is stable as many Adobe products depend on it. See: https://adobeexchangeec.zendesk.com/hc/en-us/articles/360024277392-Adobe-Experience-Cloud-Using-the-ECID-for-integration#:~:text=If%20you%20have%20code%20on,and%20the%20Experience%20Cloud%20ID.
   The values are pipe delimited.
   The value we want is MCMID.
   An example AMCV cookie value is:
   179643557|MCIDTS|19699|MCMID|13171249592012704360966409508033260498|MCAAMLH-1702596696|8|MCAAMB-1702596696|RKhpRz8krg2tLO6pguXWp5olkAcUniQYPHaMWWgdJ3xzPWQmdj0y|MCOPTOUT-1701999096s|NONE|MCAID|NONE|vVersion|5.5.0'
*/

/**
 * Retrieves the visitor ID from cookies array.
 * @param cookies - A string containing the cookies.
 * @param cookieName - Name of the cookie to retrieve the visitor ID from.
 * @returns The visitor ID string, or undefined if not found.
 */
export function getVisitorId(cookies: string, cookieName: string): string | undefined {
  const cookieMap: { [key: string]: string } = cookies.split('; ').reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    acc[key.trim()] = decodeURIComponent(value);
    return acc;
  }, {});

  const adobeCookieValue = cookieMap[cookieName];
  const matchResult = adobeCookieValue?.match(/MCMID\|(\w+)\|/);
  const mcmid = matchResult ? matchResult[1] : undefined;
  logger.info('getVisitorId.ts returns', mcmid);
  return mcmid;
}
