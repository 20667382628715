import gql from 'graphql-tag';

export interface IProductQueryData {
  catalogProductCollection: {
    items: {
      productCode: string;
      priceValue: number;
    }[];
  };
  catalogOfferCollection: {
    items: {
      id: string;
      name: string;
    }[];
  };
}

const productQuery = gql`
  query loadOfferAndProductInfo {
    catalogProductCollection(where: { productFamily_exists: true }) {
      items {
        productCode
        priceValue
      }
    }

    catalogOfferCollection {
      items {
        id
        name
      }
    }
  }
`;

export default productQuery;
