import { Entry } from 'contentful';
import { IRichText } from '@belong/types/ui';
import { Document } from '@contentful/rich-text-types';
import { FRichText } from '../richText.factory';

export interface IEntryMoleculeText {
  key?: string;
  text: string;
}

export interface IEntryMoleculeRichText {
  key?: string;
  text: Document;
}

export const FMoleculeText = (data: Entry<IEntryMoleculeText>): string => {
  return data.fields.text;
};
export const FMoleculeRichText = (data: Entry<IEntryMoleculeRichText>): IRichText => {
  return FRichText(data.fields.text, data.sys.id);
};
