import { AnalyticsEvent, EVENT_NAME } from '@belong/analytics';

export const sendToAdobe = (event: AnalyticsEvent): void => {
  window.dispatchEvent(new CustomEvent(EVENT_NAME, { detail: event.detail }));
};

export const sendToGtm = (event: AnalyticsEvent): void => {
  try {
    (window as any).dataLayer.push({
      event: EVENT_NAME,
      ...event.detail
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('error sending data to datalayer:');
    // eslint-disable-next-line no-console
    console.debug(e);
  }
};
