import { Entry } from 'contentful';
import { parseFlexibleContent } from '../parseFlexibleContent';
import { INbnDashboardTile, IOrganismNbnService } from '../../../types';
import { FLink, IEntryLink } from '../elements';
import { FMoleculeAlert, IEntryMoleculeAlert } from '../molecules/moleculeAlert.factory';
import { FMoleculeTileHeader, IEntryTileHeader } from '../molecules/moleculeTileHeader.factory';
import {
  FMoleculeRichText,
  FMoleculeText,
  IEntryMoleculeRichText,
  IEntryMoleculeText
} from '../molecules/moleculeText.factory';
import {
  IEntryNbnServiceTileContent,
  FMoleculeNbnServiceTileContent
} from '../molecules/moleculeNbnServiceTile.factory';
import { FOrganismServiceLinks, IEntryOrganismServiceLinks } from './organismServiceLinks.factory';

type DashboardServiceTileTypes =
  | IEntryLink
  | IEntryMoleculeAlert
  | IEntryMoleculeText
  | IEntryMoleculeRichText
  | IEntryOrganismServiceLinks
  | IEntryTileHeader;

interface IEntryNbnServiceTile {
  key?: string;
  serviceTiles: Entry<IEntryNbnServiceTileContent>[];
  dashboardServiceTile: Entry<DashboardServiceTileTypes>[];
}

const contentFactoryMap = new Map([
  ['elementLink', { factory: FLink }],
  ['moleculeAlert', { factory: FMoleculeAlert }],
  ['moleculeNbnServiceTileContent', { factory: FMoleculeNbnServiceTileContent }],
  ['moleculeRichText', { factory: FMoleculeRichText }],
  ['moleculeText', { factory: FMoleculeText }],
  ['moleculeTileHeader', { factory: FMoleculeTileHeader }],
  ['organismServiceLinks', { factory: FOrganismServiceLinks }]
]);

export const FOrganismNbnServiceTile = ({ fields, sys }: Entry<IEntryNbnServiceTile>): IOrganismNbnService => {
  const { key, dashboardServiceTile, serviceTiles } = fields;
  return {
    key,
    contentId: sys.id,
    dashboardServiceTile: parseFlexibleContent(dashboardServiceTile, contentFactoryMap) as INbnDashboardTile,
    serviceTiles: parseFlexibleContent(serviceTiles, contentFactoryMap)
  };
};
