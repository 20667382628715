import { createClient } from 'contentful';
import { IContentfulSettings } from '@belong/types';

interface IProps {
  contentfulSettings: IContentfulSettings;
  tags: string[];
  // optionally only get entries of a certain type (eg. only `Page`)
  contentType?: string;
}

export const getEntriesByTag = async ({
  contentfulSettings,
  tags,
  contentType
}: IProps): Promise<{
  statusCode?: number;
  items?: any[];
}> => {
  const contentfulClient = createClient(contentfulSettings);

  try {
    // warning. query is mutated below
    const query: any = {
      // note that for some reason if your `accessToken` doesn't have read-access
      // to `master` environment, this will return a `404` no matter what the
      // value of `env` passed in. I don't understand why. Might be a SDK bug.

      // the `[in]` specifier means OR search instead of AND search, ie. tag A OR tag B
      // more info: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags/querying-content-based-on-a-set-of-tags
      'metadata.tags.sys.id[in]': tags.join(',').replace('[', '').replace(']', ''),
      include: 10,
      limit: 3
    };

    if (contentType) {
      // eslint-disable-next-line camelcase
      query.content_type = contentType;
    }

    const { errors, total, items } = await contentfulClient.getEntries(query);

    // Payload returned with errors.
    if (errors) {
      return { statusCode: 500 };
    }
    // Payload returned with no content.
    if (!total) {
      return { statusCode: 404 };
    }
    return { items };
  } catch (err) {
    // Request failed.
    return { statusCode: 500 };
  }
};
