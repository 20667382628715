import dynamic from 'next/dynamic';

export const sectionContentSwitcher = dynamic(() => import('./sectionContentSwitcher/view'));
export const sectionServiceQualification = dynamic(() => import('./sectionServiceQualification/view'));
export const sectionInternetPlansDisplay = dynamic(() => import('./sectionInternetPlansDisplay/view'));
export const sectionModemSelection = dynamic(() => import('./sectionModemSelection/view'));
export const sectionPlanSummary = dynamic(() => import('./sectionPlanSummary/view'));
export const sectionSimTypePicker = dynamic(() => import('./sectionSimTypePicker/view'));
export const sectionReferralAutoSq = dynamic(() => import('./sectionReferralAutoSq/view'));
export const sectionSSBISq = dynamic(() => import('./sectionSSBISq/view'));
export const sectionSearchResults = dynamic(() => import('./sectionSearchResults/view'));
export const sectionOrderSummary = dynamic(() => import('./sectionOrderSummary/view'));
