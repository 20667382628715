/**
 * Returns a Supplemental data ID.
 * The format must be a 33 character string in the following format:
 * <16 hexadecimal digits>-<16 hexadecimal digits>
 */
export function getSupplementalDataId(): string {
  const characters: string = '0123456789ABCDEF';
  let randomHexPart1: string = '';
  let randomHexPart2: string = '';

  // Generate the first part of the string
  while (randomHexPart1.length < 16) {
    const randomIndex: number = Math.floor(Math.random() * characters.length);
    randomHexPart1 += characters[randomIndex];
  }

  // Generate the second part of the string
  while (randomHexPart2.length < 16) {
    const randomIndex: number = Math.floor(Math.random() * characters.length);
    randomHexPart2 += characters[randomIndex];
  }

  return `${randomHexPart1}-${randomHexPart2}`;
}
