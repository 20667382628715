import { IContentfulSettings, ICatalogOffer, IOfferCampaign, OFFER_CHANNEL } from '@belong/types';
import { getConfig } from '@belong/configs/next/config';
import { Cookies } from 'react-cookie';
import {
  getContentfulSettings,
  isErrorResponse,
  getEntries,
  getContentByGQL,
  isGQLErrorResponse
} from '@belong/contentful';
import { OFFER_TYPE } from '@belong/types/api/offers';
import { logger } from '@belong/logging';
import { FCatalogOffer, FOfferCampaign, IProductData, ISiteOfferContent } from './factories';
import productQuery, { IProductQueryData } from './queries/products.gql';

export async function loadSiteOfferContent(correlationId: string, cookieString?: string): Promise<ISiteOfferContent> {
  const contentfulSettings: IContentfulSettings = getContentfulSettings(
    new Cookies(cookieString),
    getConfig().publicRuntimeConfig.contentful
  );

  const [offers, campaigns, { products, offerNames }] = await Promise.all([
    loadActiveOffers(correlationId, contentfulSettings),
    loadActiveCampaigns(correlationId, contentfulSettings),
    loadProductData()
  ]);

  return { offers, campaigns, products, offerNames };
}

async function loadActiveOffers(
  correlationId: string,
  contentfulSettings: IContentfulSettings
): Promise<ICatalogOffer[]> {
  const response = await getEntries(
    {
      // eslint-disable-next-line camelcase
      content_type: 'catalogOffer',
      'fields.isActive': true,
      'fields.isATL': true,
      'fields.type': OFFER_TYPE.CHANNEL,
      'fields.eligibleChannels[in]': OFFER_CHANNEL.DIGITAL,
      include: 10
    },
    contentfulSettings,
    correlationId
  );

  if (isErrorResponse(response)) {
    logger.error('loadATLOffers - Failed to load offer content');
    return [];
  }
  return response.map(FCatalogOffer);
}

async function loadActiveCampaigns(
  correlationId: string,
  contentfulSettings: IContentfulSettings
): Promise<IOfferCampaign[]> {
  const response = await getEntries(
    {
      // eslint-disable-next-line camelcase
      content_type: 'offerCampaign',
      'fields.isActive': true,
      include: 10
    },
    contentfulSettings,
    correlationId
  );

  if (isErrorResponse(response)) {
    logger.error('loadOfferCampaigns - Failed to load offer campaigns', response);
    return [];
  }
  return response.map(FOfferCampaign);
}

async function loadProductData(): Promise<IProductData> {
  const response = await getContentByGQL<IProductQueryData>(productQuery);

  if (isGQLErrorResponse(response)) {
    logger.error('OfferContentManager - Failed to load product data', response.errors);
    return { products: {}, offerNames: {} };
  }

  const products = (response.data?.catalogProductCollection?.items || []).reduce(
    (acc, product) => ({ ...acc, [product.productCode]: product.priceValue }),
    {} as Record<string, number>
  );

  const offerNames = (response.data?.catalogOfferCollection?.items || []).reduce(
    (acc, offer) => ({ ...acc, [offer.id]: offer.name }),
    {} as Record<string, string>
  );

  return { products, offerNames };
}
