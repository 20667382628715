import { Entry } from 'contentful';
import { Document } from '@contentful/rich-text-types';
import { INTERNET_SERVICE_STATUS, INbnServiceTileContent } from '@belong/types';
import { parseFlexibleContent } from '../parseFlexibleContent';
import { FRichText } from '../richText.factory';
import { FLink, IEntryLink } from '../elements/elementLink.factory';
import { FMoleculeAlert, IEntryMoleculeAlert } from './moleculeAlert.factory';
import { FMoleculeTileHeader, IEntryTileHeader } from './moleculeTileHeader.factory';

export interface IEntryNbnServiceTileContent {
  key: INTERNET_SERVICE_STATUS;
  bodyContent?: Document;
  tileHeader: Entry<IEntryTileHeader>;
  alerts?: Entry<IEntryMoleculeAlert>[];
  primaryAction?: Entry<IEntryLink>;
  secondaryAction?: Entry<IEntryLink>;
}

const contentFactoryMap = new Map([['moleculeAlert', { factory: FMoleculeAlert }]]);

export const FMoleculeNbnServiceTileContent = (data: Entry<IEntryNbnServiceTileContent>): INbnServiceTileContent => {
  const { fields, metadata } = data;
  const { alerts, bodyContent, key, tileHeader, primaryAction, secondaryAction } = fields;
  return {
    key,
    tags: metadata.tags.map(tag => tag?.sys?.id),
    bodyContent: bodyContent && FRichText(bodyContent),
    tileHeader: FMoleculeTileHeader(tileHeader),
    primaryAction: primaryAction && FLink(primaryAction),
    secondaryAction: secondaryAction && FLink(secondaryAction),
    alerts: alerts && parseFlexibleContent(alerts, contentFactoryMap)
  };
};
