import type {
  IEntryGraphInputGeneric,
  IGenericInputFactory,
  IEntryInputGeneric,
  IErrorMessageEntry,
  IErrroMessage,
  IGenericInputErrorMap,
  ValidationMessage,
  ContentfulErrorKeys
} from '@belong/types';
import { ContentfulCollection } from 'contentful';

export function parseErrorMessages<E = IGenericInputErrorMap>(errorMessage: IErrorMessageEntry = []): E {
  return errorMessage.reduce(
    (acc, { fields: { errorKey, message } }) => ({
      ...acc,
      [errorKey]: message
    }),
    {}
  ) as E;
}

export function parseGraphErrorMessages<E = IGenericInputErrorMap>(
  errorMessage: ContentfulCollection<IErrroMessage>['items'] = []
): E {
  return errorMessage.reduce(
    (acc, { errorKey, message }) => ({
      ...acc,
      [errorKey]: message
    }),
    {}
  ) as E;
}

export function FGenericInput<T extends ContentfulErrorKeys>(data: IEntryInputGeneric): IGenericInputFactory<T> {
  const { errorMessages = [], ...fields } = data.fields;

  return {
    id: data.sys.id,
    ...fields,
    errorMessage: parseErrorMessages<ValidationMessage<T>>(errorMessages)
  };
}

export function FGraphGenericInput<T extends ContentfulErrorKeys>(
  data: IEntryGraphInputGeneric
): IGenericInputFactory<T> {
  const { sys, errorMessages, ...fields } = data;

  return {
    id: data.sys.id,
    ...fields,
    errorMessage: parseGraphErrorMessages<ValidationMessage<T>>(errorMessages?.items)
  };
}
