import { createClient, CreateClientParams, Entry } from 'contentful';
import { logger } from '@belong/logging';

export type IGetEntriesResponse = Entry<any>[] | { statusCode: number };
export const isErrorResponse = (response: IGetEntriesResponse): response is { statusCode: number } => {
  return (response as { statusCode: number }).statusCode >= 400;
};

export async function getEntries(
  query: Record<string, string | number | boolean>,
  clientSettings: CreateClientParams,
  correlationId?: string
): Promise<IGetEntriesResponse> {
  const client = createClient(clientSettings);

  try {
    // ensure the includes value is set in order to ensure linked entries are returned correctly
    const { errors, items = [] } = await client.getEntries({ ...query, include: query.include || 10 });

    // Payload returned with errors
    if (errors) {
      logger.error(correlationId, 'ContentfulAPI::contentfulRequest()', { operation: query, errors });
      return { statusCode: 500 };
    }

    return items;
  } catch (err) {
    // Request failed
    logger.error(correlationId, 'ContentfulAPI::contentfulRequest()', { operation: query, error: err });
    return { statusCode: 500 };
  }
}
