import { PRODUCT_FAMILY } from '@belong/types';
import { OfferContentManager } from '../index';

// MOBILE-PLAN-PAGE: Override to replace the default banner with an ACTIVE campaign banner
export const override = <T = any>(
  correlationId: string,
  contentManager: OfferContentManager,
  section: T,
  productFamily?: PRODUCT_FAMILY
): T => {
  return contentManager.getCampaignOverride<T>(correlationId, 'mobilePlanPageBanner', section, productFamily);
};

export default {
  // always include base-path in paths
  path: ['/go/mobile'],
  contentType: ['sectionPrimaryBanner'],
  override
};
