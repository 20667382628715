import { COLOURS, mediaMap } from '@belong/themes';
import { FlexAlignmentProperties, StyledCSS } from '@belong/types';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { ISkeletonProps } from './Skeleton.types';

const getStyles = ({
  color = COLOURS.GREY_300,
  highlightColor = COLOURS.GREY_200
}: ISkeletonProps): StyledCSS<ISkeletonProps> => css`
  background-color: ${color};
  background-image: linear-gradient(90deg, ${color} 0%, ${highlightColor} 50%, ${color} 100%);
  animation: pulse 1.6s linear infinite;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
  width: 100%;

  @keyframes pulse {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
`;

export const SkeletonPulse = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['color', 'width'].includes(prop) && defaultValidatorFn(prop)
})<Partial<ISkeletonProps>>`
  display: inline-block;

  ${getStyles}
`;

const getBaseStyles = ({ circle, height, width }: ISkeletonProps): StyledCSS<ISkeletonProps> => {
  const widthStyles = mediaMap(
    width || '100%',
    (value: string) => css`
      width: ${value};
    `
  );
  const heightStyles = mediaMap(
    height || 'auto',
    (value: string) => css`
      height: ${value};
    `
  );

  return circle
    ? css`
        ${widthStyles};
        ${heightStyles};
        border-radius: 50%;
      `
    : css`
        ${widthStyles};
        ${heightStyles};
        border-radius: 3px;
      `;
};

export const SkeletonLine = styled(SkeletonPulse)<ISkeletonProps>`
  ${getBaseStyles};
  display: block;

  &::before {
    content: '\\00a0';
  }
`;

export const Spacer = styled.div`
  margin-bottom: 1rem;
`;

interface ILayoutProps {
  marginBottom?: string;
  spaceBetween?: boolean;
  alignItems?: FlexAlignmentProperties;
  justifyContent?: FlexAlignmentProperties;
}

export const Layout = styled.div<ILayoutProps>`
  position: relative;
  display: flex;
  ${({ spaceBetween }: ILayoutProps): FlattenSimpleInterpolation =>
    spaceBetween
      ? css`
          justify-content: space-between;
        `
      : css``};
  ${({ alignItems }: ILayoutProps): FlattenSimpleInterpolation =>
    alignItems
      ? css`
          align-items: ${alignItems};
        `
      : css``};
  ${({ justifyContent }: ILayoutProps): FlattenSimpleInterpolation =>
    justifyContent
      ? css`
          justify-content: ${justifyContent};
        `
      : css``};
  margin-bottom: ${({ marginBottom }): string | StyledCSS => marginBottom || `0`};
`;
