import homepageBanner from './homepage.override';
import mobilePlanPageBanner from './mobilePlanPage.override';
import serviceAddTile from './serviceAddTile.override';
import mobilePlanSummary from './mobilePlanSummary.override';
import { OfferContentManager } from '../OfferContentManager';

export type ISectionOverride = <T = any>(
  correlationId: string,
  promoContentManager: OfferContentManager,
  section: T
) => T;

interface ISectionOverridesDefinition {
  path: string[];
  contentType: string[];
  override: ISectionOverride;
}

export const overrides: ISectionOverridesDefinition[] = [
  homepageBanner,
  mobilePlanPageBanner,
  serviceAddTile,
  mobilePlanSummary
];
