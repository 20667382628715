import styled, { css } from 'styled-components';

import { IImage } from '@belong/types';
import { CONTAINER_MAX_WIDTHS, media, mediaMap } from '@belong/themes';

import { Column, Columns } from '../../ui';

type ImageWrapperProps = Pick<IImage, 'contentType'>;
const getObjectFit = ({ contentType }: ImageWrapperProps): string => (contentType === 'svg' ? 'contain' : 'cover');
const horizontalPadding = { xs: '1.6rem', md: '2.2rem', lg: '2.6rem' };

export const HeaderColumns = styled(Columns)`
  ${Column} {
    &:last-child {
      ${mediaMap(
        horizontalPadding,
        padding => css`
          padding-left: ${padding};
          padding-right: ${padding};
        `
      )}
    }

    &:not(&:only-child) {
      // IMAGE CELL - ensure image consumes full space of column
      &:first-child:not(&:only-child) {
        margin: 0;
        padding: 0;

        > * {
          height: 100%;
          max-height: 592px;
          ${media('lg')`min-height: 496px;`};
        }
      }

      // INFO CELL
      &:last-child {
        // Apply max-width equal to half of std container size in order to line up with content
        ${mediaMap(
          CONTAINER_MAX_WIDTHS,
          maxWidth => css`
            max-width: calc(${maxWidth} / 2);
          `
        )};
      }
    }
  }
`;

export const ImageWrapper = styled.div<ImageWrapperProps>`
  img,
  picture {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: ${getObjectFit};
  }
`;
