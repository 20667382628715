import { ILink, ISectionAction, ISectionInfo, XAlignValue } from '@belong/types';
import slugify from 'slugify';
import { FLink } from '../elements';
import { FImage } from '../image.factory';
import { FRichText } from '../richText.factory';

const FAction = (action: ILink): ISectionAction => {
  return { ...action, children: action.label, variant: action.variant.toLowerCase() };
};

const FSectionInfoGraphQL = ({
  sys,
  identifier,
  anchorName,
  sectionStyles,
  bodyAlignment,
  image,
  headingContent,
  bodyContent,
  buttonAlignment,
  primaryAction,
  secondaryAction
}): ISectionInfo => {
  return {
    contentType: 'sectionInfo',
    id: sys.id,
    identifier: slugify(identifier),
    anchorName: anchorName && slugify(anchorName),
    sectionStyles,
    headingContent: FRichText(headingContent.json),
    bodyContent: bodyContent && FRichText(bodyContent.json),
    bodyAlignment: bodyAlignment?.toLowerCase() as XAlignValue,
    buttonAlignment: buttonAlignment?.toLowerCase() as XAlignValue,
    image: image && FImage(image),
    primaryAction: primaryAction && FAction(primaryAction),
    secondaryAction: secondaryAction && FAction(secondaryAction)
  };
};

const FSectionInfoContentAPI = (data: any): ISectionInfo => {
  const {
    identifier,
    anchorName,
    sectionStyles,
    bodyAlignment,
    image,
    headingContent,
    bodyContent,
    buttonAlignment,
    primaryAction,
    secondaryAction,
    key
  } = data.fields;

  return {
    contentType: 'sectionInfo',
    id: data.sys.id,
    key,
    identifier: slugify(identifier),
    anchorName: anchorName && slugify(anchorName),
    sectionStyles,
    headingContent: FRichText(headingContent),
    bodyContent: bodyContent && FRichText(bodyContent),
    bodyAlignment: bodyAlignment?.toLowerCase() as XAlignValue,
    buttonAlignment: buttonAlignment?.toLowerCase() as XAlignValue,
    image: image && FImage(image),
    primaryAction: primaryAction && FLink(primaryAction),
    secondaryAction: secondaryAction && FLink(secondaryAction)
  };
};

export const FSectionInfo = (data: any): ISectionInfo => {
  return data.fields ? FSectionInfoContentAPI(data) : FSectionInfoGraphQL(data);
};
