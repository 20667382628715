import { logger } from '@belong/logging/logger';
import { createSectionModel as getGlobalSectionModel } from '@belong/ui-sections';
import { getEntriesByTag } from '@belong/contentful';
import { Entry, IContentfulSettings } from '@belong/types';

// Load the model transformer for a local section
const getLocalSectionModel = async (section: any): Promise<any> => {
  const contentType = section.sys.contentType.sys.id;
  const model = await import(`../components/sections/${contentType}`).then(importedModule => {
    return importedModule.default.model;
  });

  return model(section);
};

const handleError = (contentType: string, err: Error): void => {
  // only log messages relating to transformation errors
  if (!err.message.includes('Cannot find module')) {
    logger.error(new Error(`Failed to transform props for '${contentType}'`));
  }
};

export const getParsedSection = async (section: any): Promise<any> => {
  const contentType = section.sys.contentType.sys.id;
  try {
    // try to create and return a model from local sections
    const model = await getLocalSectionModel(section);
    if (!model || Object.keys(model).length === 0) {
      throw new Error('Cannot find module');
    }
    return model;
  } catch (err) {
    handleError(contentType, err);
  }

  try {
    // try to create and return a model from ui-sections
    const model = await getGlobalSectionModel(section);
    return model;
  } catch (err) {
    handleError(contentType, err);
  }

  logger.error(`No factory found for '${contentType}'`);
  return null;
};

const PAGE_CONTENT_TYPE = 'page';
const CATEGORY_TAG_PREFIX = 'category';

/**
 * Find related pages by tags (for blog articles)
 */
export const getRelatedArticles = async (
  pageProps: any,
  contentfulSettings: IContentfulSettings
): Promise<Entry<any>[]> => {
  try {
    // @ts-ignore
    const { tags } = pageProps[0].metadata;
    // NOTE. the tag sys.id is the tag ID, not the tag name
    const categoryTags = tags.map(t => t.sys.id).filter(t => t.toLowerCase().startsWith(CATEGORY_TAG_PREFIX));

    if (categoryTags.length) {
      const entries = (
        await getEntriesByTag({
          contentfulSettings,
          tags: categoryTags,
          contentType: PAGE_CONTENT_TYPE
        })
      ).items;

      // Ensure entries is always an array
      return entries ?? [];
    }
  } catch (e) {
    logger.error('Errror getting the related articles', e);
  }
  return [];
};
