import type { IPageAlert } from '@belong/types';
import { ALERT_POSITION } from '@belong/types';

export const FPageAlert = (data: any): IPageAlert => {
  const { text, status, content } = data.fields;

  return {
    children: text,
    type: status?.toUpperCase(), // ALERT_LEVELS are all uppercase
    position: ALERT_POSITION.GLOBAL,
    content
  };
};
