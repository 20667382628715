export interface IErrorPassword {
  required: string;
  strength: string;
}

export enum AUTOCOMPLETE_TYPE {
  CURRENT_PASSWORD = 'current-password',
  NEW_PASSWORD = 'new-password'
}

export type TPasswordName = 'password' | 'current-password' | 'new-password';

export interface IPassword {
  id: string;
  name: TPasswordName;
  contentType: 'inputPassword';
  label: string;
  description?: string;
  a11yLabel: string;
  a11yHide: string;
  a11yShow: string;
  autoComplete: AUTOCOMPLETE_TYPE;
  errorMessage: IErrorPassword;
}

export const FPassword = (data: any): IPassword => {
  const {
    label,
    description,
    a11yHide,
    a11yLabel,
    a11yShow,
    autoComplete,
    errorMessageRequired,
    errorMessagePasswordStrength
  } = data.fields;
  return {
    label,
    description,
    a11yShow,
    a11yLabel,
    a11yHide,
    id: data.sys.id,
    contentType: data.sys.contentType.sys.id,
    name: 'password',
    autoComplete: AUTOCOMPLETE_TYPE[autoComplete as 'CURRENT_PASSWORD' | 'NEW_PASSWORD'],
    errorMessage: {
      required: errorMessageRequired,
      strength: errorMessagePasswordStrength
    }
  };
};
