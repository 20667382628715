export const VENDOR_LOAD_CHECK_FREQUENCY = 1000; // ms

/**
 * Technically this doesn't strictly tell us if Google Analytics has loaded,
 * but it is our main dependency to call `sendToGtm()` elsewhere. This is
 * acceptable because Google appears to pick up any operations that occurred to
 * `window.dataLayer` before it was loaded.
 *
 * You might think that means we don't need to catch the early events to hold
 * them until GTM is ready, but that's not true. We still need to do that because
 * React runs the the initial render (and the ensuing `pageview` event) before
 * the script that defines the `window.dataLayer` property.
 */
export const hasGtmLoaded = (): boolean => {
  return !!(window as any).dataLayer;
};

export const hasAdobeLoaded = (): boolean => {
  // eslint-disable-next-line no-underscore-dangle
  return !!(window as any).__satelliteLoaded;
};

export const checkForGtm = (cb: () => void): void => {
  if (!hasGtmLoaded()) {
    setTimeout(() => checkForGtm(cb), VENDOR_LOAD_CHECK_FREQUENCY);
    return;
  }

  cb();
};

export const checkForAdobe = (cb: () => void): void => {
  if (!hasAdobeLoaded()) {
    setTimeout(() => checkForAdobe(cb), VENDOR_LOAD_CHECK_FREQUENCY);
    return;
  }

  cb();
};

export const waitForGtm = (): Promise<void> => new Promise(checkForGtm);
export const waitForAdobe = (): Promise<void> => new Promise(checkForAdobe);
