import { IMediaCaptionBlock } from '@belong/types/index';
import { FImage } from '../image.factory';
import { FVideo } from '../video.factory';

export const FMoleculeMediaCaptionBlock = (data: any): IMediaCaptionBlock => {
  const { caption, image, imageAspectRatio, video, videoId } = data.fields;

  return {
    caption,
    image: image && FImage(image),
    aspectRatio: imageAspectRatio,
    video: video && FVideo(video),
    videoId
  };
};
