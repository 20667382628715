import type { Entry, IOrganismServiceLinks } from '@belong/types';
import { FLinkList, FMoleculeTileHeader } from '../molecules';

export interface IEntryOrganismServiceLinks {
  id: string;
  header: any;
  linkList: any;
  key?: string;
}

export const FOrganismServiceLinks = (data: Entry<IEntryOrganismServiceLinks>): IOrganismServiceLinks => {
  const { id } = data.sys;

  const { header, linkList } = data.fields;

  return {
    contentType: 'organismServiceLinks',
    header: header && FMoleculeTileHeader(header),
    id,
    linkList: { items: FLinkList(linkList) }
  };
};
