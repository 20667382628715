import { Entry } from 'contentful';
import { ICatalogOffer, ICatalogOfferReward, isEntry, OFFER_CHANNEL, ProductAttributeMap } from '@belong/types';
import { IOffer, IOfferReward, IOfferRewardProductAttribute } from '@belong/types/api/offers';

export const collectProductAttributes = (attributes: IOfferRewardProductAttribute[] = []): ProductAttributeMap => {
  return attributes.reduce((acc, attr) => {
    acc[attr.name] = attr.value;
    return acc;
  }, {});
};

export const FCatalogOfferReward = (data: IOfferReward | Entry<IOfferReward>): ICatalogOfferReward => {
  const fields = isEntry(data) ? data.fields : data;
  const { productId, description } = fields;

  return {
    productId,
    description,
    productAttributes: Array.isArray(fields.productAttributes)
      ? collectProductAttributes(fields.productAttributes)
      : fields.productAttributes
  };
};

export const FCatalogOffer = (data: IOffer | Entry<IOffer>): ICatalogOffer => {
  const fields = isEntry(data) ? data.fields : data;
  const {
    id,
    name,
    type,
    description,
    groupingId,
    productFamily,
    isATL,
    isActive,
    eligibleChannels = [OFFER_CHANNEL.DIGITAL],
    eligiblePlans,
    channelAction,
    startDate,
    endDate,
    rewards
  } = fields;

  // if triggers are omitted, set channel to 'digital'
  return {
    id,
    name,
    description,
    groupingId,
    productFamily,
    type,
    isATL,
    isActive,
    channelAction,
    eligibleChannels,
    eligiblePlans: eligiblePlans || [],
    startDate,
    endDate,
    rewards: rewards.map(FCatalogOfferReward)
  };
};
