import {
  IEntryInputGeneric,
  IEntryInputGenericGroup,
  IInputGenericGroup,
  IInputGenericGroupOption
} from '@belong/types';

import { FGenericInput } from './genericInput.factory';

const FGenericInputWithValue = <Values extends string>(
  inputGeneric: IEntryInputGeneric
): IInputGenericGroupOption<Values> => {
  const { description, id, label, key: value = '' } = FGenericInput(inputGeneric);

  return {
    description,
    id,
    label,
    value: value as Values
  };
};

export function FGenericInputGroup<Values extends string = ''>(
  data: IEntryInputGenericGroup
): IInputGenericGroup<Values> {
  const { options, groupTitle, ...fields } = data.fields;

  return {
    id: data.sys.id,
    ...fields,
    legend: groupTitle,
    options: options.map(FGenericInputWithValue<Values>)
  };
}
