// this function returns the QA mode to the request object if the query params are present.

import { IQAMode } from './types';

export const getQAMode = (query: any): { qaMode: IQAMode } | Record<string, never> => {
  const splitSeparator = '_';

  // find the query params that are required for QA mode.
  const hasRequiredPreviewQueries =
    query &&
    Object.keys(query).some(key => key.startsWith('at_preview_token')) &&
    Object.keys(query).some(key => key.startsWith('at_preview_index'));

  // if any of the index values is not of the format "activityIdx_experienceIdx" this should be false.
  const idxFormatCorrect = !query.at_preview_index?.split(',').some(
    (idxVal: string) =>
      idxVal.split(splitSeparator).filter(val => {
        return !!val && !Number.isNaN(Number(val));
      }).length !== 2
  );

  // if any of the above conditions are not met we should return empty object
  if (!hasRequiredPreviewQueries || !idxFormatCorrect) {
    return {};
  }

  const qaMode: IQAMode = {
    token: query.at_preview_token,
    previewIndexes: (query.at_preview_index ?? '').split(',').map((idxPair: string) => {
      const idxPairSplit = idxPair.split(splitSeparator);
      return {
        activityIndex: Number(idxPairSplit[0]),
        experienceIndex: Number(idxPairSplit[1])
      };
    })
  };
  if (query.at_preview_listed_activities_only) {
    qaMode.listedActivitiesOnly = query.at_preview_listed_activities_only;
  }
  if (query.at_preview_evaluate_as_true_audience_ids) {
    qaMode.evaluateAsTrueAudienceIds = (query.at_preview_evaluate_as_true_audience_ids ?? '').split(',');
  }
  if (query.at_preview_evaluate_as_false_audience_ids) {
    qaMode.evaluateAsFalseAudienceIds = (query.at_preview_evaluate_as_false_audience_ids ?? '').split(',');
  }

  return { qaMode };
};
