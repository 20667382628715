import React from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import { ArticleHeader, LinkList, Section, RichText, TEXT_NODES, Columns, Column } from '@belong/ui-components';
import { ARTICLE_BLOG_VIEW_TEST_ID, Entry, IPage } from '@belong/types';
import { CardBase, CardHeading, CardInner } from '@belong/ui-components/components/contentBlocks/CardV2';
import SectionArticleGroup from '@belong/ui-sections/sections/sectionArticleGroup/view';
import { logger } from '@belong/logging/logger';

import ContentfulSection from '../ContentfulSection';

const transformRelatedArticles = (relatedArticles: Array<Entry<any>> = [], currentPage: IPage) => {
  try {
    return relatedArticles
      .filter(item => {
        const articleContent = item.fields.page ?? item.fields.blogArticle;
        return articleContent.identifier !== currentPage.identifier;
      }) // exclude current page
      .map(item => ({
        label: item.fields.pageName,
        href: item.fields.pageUrl.fields.url,
        caption: documentToPlainTextString(item.fields.description) // description is a required prop
      }));
  } catch (e) {
    logger.error('Error transforming related articles', e);
    return [];
  }
};

const ArticleBlogView = ({ page, relatedArticles }: { page: IPage; relatedArticles: any[] }) => {
  const relatedArticlesTransformed = transformRelatedArticles(relatedArticles, page);

  const { blogArticle: article } = page;

  if (!article) {
    return null;
  }

  return (
    <>
      <Section
        isFullWidth
        isPadded={false}
        hasBackground="grey"
        data-testid={ARTICLE_BLOG_VIEW_TEST_ID.SECTION_ARTICLE_HEADER}
      >
        <ArticleHeader heading={article.heading} content={article.subheading} image={article.coverImage} />
      </Section>

      <Section data-testid={ARTICLE_BLOG_VIEW_TEST_ID.SECTION_BODY} hasPaddingTop hasPaddingBottom>
        <Columns space="small" collapseBelow="lg">
          <Column width={{ lg: '2/3' }}>
            {article.articleBody && (
              <RichText
                h1={TEXT_NODES.headingLarge}
                h2={TEXT_NODES.headingMedium}
                h3={TEXT_NODES.headingSmall}
                h4={TEXT_NODES.headingXSmall}
                html={article.articleBody.html}
              />
            )}
          </Column>
          <Column width={{ lg: '1/3' }}>
            {relatedArticlesTransformed.length > 0 && (
              <aside>
                <RelatedArticles heading="Related articles" items={relatedArticlesTransformed} />
              </aside>
            )}
          </Column>
        </Columns>
      </Section>
      {article.sections.map(props => (
        <ContentfulSection key={props?.id} {...props} />
      ))}
      {article.relatedArticleGroup && (
        <SectionArticleGroup
          {...article.relatedArticleGroup}
          // ts-ignore
          links={[]}
          sectionStyles={{ hasBackground: 'grey', hasPaddingTop: true }}
        />
      )}
    </>
  );
};

const RelatedArticles = ({ items, heading }) => (
  <CardBase
    data-testid={ARTICLE_BLOG_VIEW_TEST_ID.RELATED_ARTICLES}
    hasShadow
    hasMargin={{ xs: '2rem 0 0 0', lg: '0' }}
  >
    <CardHeading alignment="left" as="h2">
      {heading}
    </CardHeading>
    <CardInner hasPadding={{ xs: 1.6 }}>
      <LinkList items={items} />
    </CardInner>
  </CardBase>
);

export default ArticleBlogView;
