import { FunctionComponent } from 'react';
import { IImage, ILink, IRichText, OUTER_SPACING } from '@belong/types';
import { FONT_COLOURS } from '@belong/themes';
import useRuntime from '@belong/providers/runtime';
import { getResponsiveSrc } from '../../../helpers/image';
import { Display, RichText } from '../../styles/Typography';
import OuterSpacing from '../../layout/OuterSpacing';
import { Button } from '../../actions/Button';
import ContentFormatter from '../../styles/ContentFormatter';
import ButtonGroup from '../../styles/ButtonGroup';
import { PictureContainer } from '../../media/PictureContainer';
import { HeaderColumns, ImageWrapper } from './ArticleHeader.styles';
import { Column, Stack } from '../../ui';

export interface IArticleHeader {
  categoryLink?: ILink;
  autoCategoryLink?: ILink;
  heading: string;
  publishInfo?: any;
  content?: IRichText;
  image?: IImage;
  hideImageOnMobile?: boolean;
  link?: ILink;
  hasDarkBackground?: boolean;
}

const MOBILE_BREAKPOINTS = ['xs', 'sm'];

export const TEST_ID = {
  CONTAINER: 'article-header',
  IMAGE: 'article-header-image',
  INFO: 'article-header-info',
  CATEGORY: 'article-header-category',
  HEADING: 'article-header-heading',
  CONTENT: 'article-header-content',
  CTA: 'article-header-cta'
};

export const IMAGE_SIZE = {
  xs: { w: 373, h: 210 },
  md: { w: 764, h: 430 },
  lg: { w: 882, h: 496 }
};

const ArticleHeader: FunctionComponent<IArticleHeader> = props => {
  const { currentBreakpoint } = useRuntime();
  const { autoCategoryLink, categoryLink, heading, content, link, image } = props;
  const { hasDarkBackground = false, hideImageOnMobile = false } = props;

  const isMobileView = MOBILE_BREAKPOINTS.includes(currentBreakpoint);
  const shouldShowImage = !!image && !(isMobileView && hideImageOnMobile);
  const imgSrc = image && getResponsiveSrc(image, IMAGE_SIZE, { fit: 'fill', f: 'face' });
  const textColorSettings = hasDarkBackground ? { hasColor: FONT_COLOURS.LIGHT } : undefined;
  const categoryLinkProps = categoryLink || autoCategoryLink;

  return (
    <HeaderColumns
      data-testid={TEST_ID.CONTAINER}
      alignX={isMobileView || shouldShowImage ? 'right' : 'left'}
      alignY="stretch"
      collapseBelow="lg"
      reverse="whenNotCollapsed"
    >
      {image && shouldShowImage && (
        <Column data-testid={TEST_ID.IMAGE} width="1/2">
          <ImageWrapper contentType={image.contentType}>
            <PictureContainer {...image} loading="eager" src={imgSrc} />
          </ImageWrapper>
        </Column>
      )}
      <Column data-testid={TEST_ID.INFO} width={isMobileView || shouldShowImage ? '1/2' : '2/3'}>
        <OuterSpacing spacing={OUTER_SPACING.LOOSE}>
          <Stack space={{ xs: 'large', lg: 'xlarge' }}>
            <Stack space={{ xs: 'xsmall', md: 'medium', lg: 'large' }}>
              {categoryLinkProps && (
                <Button data-testid={TEST_ID.CATEGORY} {...categoryLinkProps} variant="quaternary" />
              )}
              <Display data-testid={TEST_ID.HEADING} isSmall as="h1" {...textColorSettings}>
                {heading}
              </Display>
              {content && (
                <ContentFormatter spacing="large">
                  <RichText data-testid={TEST_ID.CONTENT} {...textColorSettings} {...content} />
                </ContentFormatter>
              )}
            </Stack>
            {link && (
              <ButtonGroup hasControl={{ xs: ['column', 'left'], md: ['row', 'left'] }}>
                <Button data-testid={TEST_ID.CTA} {...link} width={{ xs: 'full', md: 'min' }} />
              </ButtonGroup>
            )}
          </Stack>
        </OuterSpacing>
      </Column>
    </HeaderColumns>
  );
};

ArticleHeader.displayName = 'ArticleHeader';

export default ArticleHeader;
