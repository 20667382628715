import { createClient, Entry } from 'contentful';
import resolveResponse from 'contentful-resolve-response';
import { IContentfulSettings } from '@belong/types';
import { logger } from '@belong/logging/logger';

interface IPageContentRequest {
  contentfulSettings: IContentfulSettings;
  pagePath: string;
  correlationId: string;
}

export interface IPageContentResponse {
  statusCode?: number;
  items?: Entry<any>[];
}

export const getPageContent = async ({
  contentfulSettings,
  pagePath,
  correlationId
}: IPageContentRequest): Promise<IPageContentResponse> => {
  const contentfulClient = createClient(contentfulSettings);
  logger.debug(correlationId, `getPageContent('${pagePath}') - loading contentful data`);

  /**
   * CMS entries are authored with URLs that never have a trailing slash.
   */
  if (pagePath.endsWith('/')) {
    // eslint-disable-next-line no-param-reassign
    pagePath = pagePath.slice(0, -1);
  }

  try {
    const result = await contentfulClient.getEntries({
      // eslint-disable-next-line camelcase
      content_type: 'page',
      'fields.pageUrl.sys.contentType.sys.id': 'urlInternal',
      'fields.pageUrl.fields.url': pagePath,
      include: 10
    });

    const { errors, total } = result;
    // Payload returned with errors.
    if (errors) {
      logger.error(correlationId, `getPageContent('${pagePath}') - request returned errors`, { errors });
      return { statusCode: 500 };
    }
    // Payload returned with no content.
    if (!total) {
      logger.error(correlationId, `getPageContent('${pagePath}') - no results`);
      return { statusCode: 404 };
    }
    // Loaded content
    return { items: resolveResponse(result) };
  } catch (err) {
    logger.error(correlationId, `getPageContent('${pagePath}') - operation threw error`, { err });
    // Request failed.
    return { statusCode: 500 };
  }
};

// Helper for parsing raw content for tests
export const parseContent = (content: any): any => resolveResponse(content)[0];
