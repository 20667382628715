import type { Entry, IOrganismSecondaryTile } from '@belong/types';
import { FMoleculeCallout } from '../molecules';
import { FLink } from '../elements';
import { FImage } from '../image.factory';
import { FRichText } from '../richText.factory';

export interface IEntryOrganismSecondaryTile {
  image?: any;
  body: any;
  callout?: any;
  link1?: any;
  link2?: any;
  termsAndConditions?: any;
  key?: string;
}

export const FOrganismSecondaryTile = (data: Entry<IEntryOrganismSecondaryTile>): IOrganismSecondaryTile => {
  const { image, body, callout, link1, link2, termsAndConditions, key } = data.fields;

  return {
    id: data.sys.id,
    contentType: 'organismSecondaryTile',
    image: image && FImage(image),
    body: FRichText(body),
    link1: link1 && FLink(link1),
    link2: link2 && FLink(link2),
    callout: callout && FMoleculeCallout(callout),
    termsAndConditions: termsAndConditions && FRichText(termsAndConditions),
    key
  };
};
