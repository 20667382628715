export const PUBLIC_PAGES = {
  HOME: '/',
  NBN_PLANS: '/go/internet',
  MOBILE_PLANS: '/go/mobile',
  DATA_PLANS: '/go/data-plans',
  BLOG: '/go/blog',
  NBN_SERVICE_QUALIFICATION: '/go/internet/check-your-address',
  NBN_POSTSQ_PLANS: '/go/internet/nbn-plans',
  SUSTAINABLITY: '/go/sustainability',
  NBN_SSBI: '/go/internet/ssbi',
  SEARCH: '/go/search'
};
