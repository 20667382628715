import React, { FunctionComponent } from 'react';
import { COLOURS, SHADOWS } from '@belong/themes';
import styled from 'styled-components';
import Skeleton from '../../feedback/Skeleton';
import * as Styles from './ArticleTile.styles';

type ISkeletonProps = { isHero?: boolean };

export const SkeletonSlim: FunctionComponent<any> = () => (
  <>
    <Styles.SkeletonContainer>
      <Skeleton width="50%" height="2rem" />
      <Skeleton width="100%" height="2rem" />
      <Skeleton width="90%" height="2rem" />
    </Styles.SkeletonContainer>
    <Styles.SkeletonContainer>
      <Skeleton width="100%" height="100%" />
    </Styles.SkeletonContainer>
  </>
);

SkeletonSlim.displayName = 'SkeletonSlim';

export const SkeletonHero: FunctionComponent<any> = () => (
  <>
    <Styles.SkeletonContainer height={130}>
      <Skeleton width="20%" height="2rem" />
      <Skeleton width="100%" height="2rem" />
      <Skeleton width="50%" height="2rem" />
    </Styles.SkeletonContainer>
    <Styles.SkeletonContainer isHero height={200}>
      <Skeleton width="100%" height="100%" />
    </Styles.SkeletonContainer>
  </>
);

SkeletonHero.displayName = 'SkeletonHero';

const ArticleTileSkeleton: FunctionComponent<ISkeletonProps> = ({ isHero }: ISkeletonProps) => {
  const SkeletonComponent = isHero ? SkeletonHero : SkeletonSlim;
  const Container = styled(Styles.Container)`
    position: relative;
    min-height: 12rem;
    background-color: ${COLOURS.WHITE};
    user-select: none;
    pointer-events: none;
    box-shadow: ${SHADOWS.AMBIENT_LIGHT};

    // ensure image skeleton stays square in slim layout
    > * {
      width: 100%;
      ${!isHero && '&:last-child { width: 110px; }'};
    }
  `;

  return (
    <Container isHero={isHero}>
      <SkeletonComponent />
    </Container>
  );
};

ArticleTileSkeleton.displayName = 'ArticleTileSkeleton';

export default ArticleTileSkeleton;
