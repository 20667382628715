export interface ICancellablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
}

/**
 * Same as `makeCancelable` however the resulting promises resolves to
 * a boolean indicating whether or not the promise was canceled.
 */
export const makeWeakCancelable = (promise: Promise<any>): ICancellablePromise<boolean> => {
  const controller = new AbortController();

  return {
    promise: new Promise(resolve => {
      controller.signal.addEventListener('abort', () => {
        resolve(true);
      });
      promise.then(() => {
        resolve(false);
      });
    }),
    cancel(): void {
      controller.abort();
    }
  };
};
