import slugify from 'slugify';
import { Entry, ISectionPrimaryBanner } from '@belong/types';
import { toCamelCase } from '@belong/utils';
import { FImage } from '../image.factory';
import { FMoleculeCallout } from '../molecules';
import { FVideo } from '../video.factory';
import { FLink } from '../elements';

export const FSectionPrimaryBanner = (data: Entry<any>): ISectionPrimaryBanner => {
  const {
    identifier,
    content,
    heading,
    copy,
    hideImageOnSmall,
    image,
    callout,
    video,
    link,
    layout,
    variation,
    leftAlignBanner
  } = data.fields;

  return {
    identifier: slugify(identifier),
    content,
    heading,
    copy,
    hideImageOnSmall,
    id: data.sys.id,
    contentType: 'sectionPrimaryBanner',
    image: image && FImage(image),
    callout: callout && FMoleculeCallout(callout),
    video: video && FVideo(video),
    link: link && FLink(link),
    layout: toCamelCase(layout) as 'textImage' | 'imageText',
    variation: variation?.toLowerCase(),
    leftAlignBanner: leftAlignBanner ?? false
  };
};
