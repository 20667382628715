import { useEffect, useMemo } from 'react';
import Head from 'next/head';
import { Cookies } from 'react-cookie';

import { getConfig } from '@belong/configs/next/config';
import { checkForPromo } from '@belong/domain-nbn-core/utils';
import { withBasePath, withoutBasePath, withoutParams } from '@belong/utils/url';
import { Entry, IContentfulSettings, PLATFORM_TYPE, USER_TYPE } from '@belong/types';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import AnalyticsContext from '@belong/providers/analyticsEvents/Context';
import useConnectToAnalyticsVendors from '@belong/react-hooks/useConnectToAnalyticsVendors';
import { useAnalyticsDebugger } from '@belong/providers/analyticsEvents/debug';
import { pageView } from '@belong/analytics';
import { getContentfulSettings, getPageContent, FPage, findEntryForPromotion } from '@belong/contentful';
import { logger } from '@belong/logging/logger';

import {
  findAdobeTargetActivity,
  updateSectionsWithAdobeTargetActivity,
  getSupplementalDataId,
  isIAdobeTargetActivityError,
  IAdobeTargetActivityResponse
} from '@belong/adobe-target';
import { useRouter } from 'next/router';
import { COOKIES, PUBLIC_PAGES } from '@belong/constants/src';
import { offerContentManager } from '@belong/offers';
import { platformConfig } from '@belong/utils';
import { checkAuthenticationCookies } from '@belong/providers/authentication';
import { getCookie } from '@belong/cookies';
import { getABTestAnalyticsData } from 'src/helpers/getABTestAnalyticsData';
import { withServerSideAppProps } from '../helpers/nextjs/withServerSideAppProps';
import type { IDynamicPageProps, PageServerSidePropsFunction } from '../core/types';
import { getParsedSection, getRelatedArticles } from '../helpers/contentful';
import { FArticleBlog, doesPageLinkToBlogArticle, isIArticleBlog } from '../contentful/factories/ArticleBlog';
import ArticleBlogView from '../components/templates/ArticleBlogView';
import PageUnauthenticatedView from '../components/templates/PageUnauthenticatedView';
import ErrorPage from './_error';

export default function DynamicPage({ pageData }: IDynamicPageProps) {
  const analyticsEvents = useAnalyticsEvents();
  const config = useMemo(() => getConfig().publicRuntimeConfig, []);
  useConnectToAnalyticsVendors(analyticsEvents, config.analytic.shouldRun);
  useAnalyticsDebugger();

  const { query } = useRouter();
  const slug = query.slug as string[];
  const isSearchResultsPage = slug && slug[0] === withoutBasePath(PUBLIC_PAGES.SEARCH).replace(/\//g, '');
  const searchQuery = (isSearchResultsPage && (query.query as string)) || undefined;

  const {
    adobeTargetActivity,
    description,
    journeyName,
    keywords,
    noFollow,
    noIndex,
    pageTitle,
    pageUrl,
    sections: pageSections,
    statusCode,
    relatedArticles
  } = pageData ?? {};

  useEffect(() => {
    analyticsEvents.send(pageView({ ...pageData }, config));
  }, [pageTitle, pageUrl, analyticsEvents]);

  if (statusCode && statusCode >= 400) {
    return <ErrorPage statusCode={statusCode} />;
  }

  if (!pageData) {
    return <ErrorPage statusCode={500} />;
  }

  const sections: { contentType?: string; [key: string]: any }[] = pageSections ?? [];

  return (
    <>
      <AnalyticsContext
        value={{
          currentUrl: pageUrl,
          journeyName,
          experiments: getABTestAnalyticsData(adobeTargetActivity)
        }}
      />
      <Head>
        <title key="title">{`${searchQuery ? `"${searchQuery}" - ` : ''}${pageTitle}`}</title>
        <meta name="description" key="description" content={description} />
        <meta name="format-detection" content="telephone=no" />
        {keywords && keywords !== '' && <meta name="keywords" key="keywords" content={keywords} />}
        {noIndex && noFollow && <meta name="robots" content="noindex, nofollow" />}
        {noIndex && !noFollow && <meta name="robots" content="noindex" />}
        {!noIndex && noFollow && <meta name="robots" content="nofollow" />}
      </Head>
      {isIArticleBlog(pageData) ? (
        <ArticleBlogView page={pageData} relatedArticles={relatedArticles ?? []} />
      ) : (
        <PageUnauthenticatedView sections={sections} />
      )}
    </>
  );
}

export const getServerSidePageProps: PageServerSidePropsFunction<IDynamicPageProps> = async (
  context,
  appServerSideProps
) => {
  const { correlationId } = appServerSideProps;
  const { req, res, resolvedUrl } = context;
  const pageUrl = withoutParams(resolvedUrl);
  const pagePath = withBasePath(pageUrl);
  logger.debug(correlationId, `Served by public: ${pageUrl}`);

  const isNativeApp = platformConfig.platform !== PLATFORM_TYPE.BROWSER;
  const { isLoggedIn, userType } = checkAuthenticationCookies(req?.cookies || new Cookies(), isNativeApp);

  const contentfulSettings: IContentfulSettings = getContentfulSettings(
    new Cookies(req?.headers.cookie),
    getConfig().publicRuntimeConfig.contentful
  );

  const { items, statusCode } = await getPageContent({
    contentfulSettings,
    pagePath,
    correlationId
  });

  if (statusCode) {
    if (res) {
      res.statusCode = statusCode;
    }

    return {
      pageData: {
        statusCode
      }
    };
  }

  const pageEntry = findEntryForPromotion(items, checkForPromo(context));

  /*
    Supplemental Data ID is sent to Adobe Target when requesting an experience,
    and then the same ID is sent to the Page Analytics call. This lets Adobe
    stitch the Target Experience together with the Analytics data so we can
    do reporting.
  */
  const supplementalDataId = getSupplementalDataId();

  const forceControlExperience = getCookie(COOKIES.NDC, context) === 'Yes'; // force control experience for NDC

  // this is where we parse the pageEntry to check for Adobe Target activities
  const adobeTargetActivityResponse = await findAdobeTargetActivity({
    ctx: context,
    sessionId: correlationId,
    supplementalDataId,
    unparsedPageEntry: pageEntry,
    isAuthenticatedCustomer: isLoggedIn && userType === USER_TYPE.CUSTOMER,
    forceControlExperience
  });

  let parsedPageEntry = pageEntry;

  // there may not be an Adobe Target or retrieving the Activity may fail
  let adobeTargetActivity: IAdobeTargetActivityResponse | undefined;

  if (adobeTargetActivityResponse) {
    // inject the results of the Adobe Target Activity Response into the page entry
    parsedPageEntry = updateSectionsWithAdobeTargetActivity({
      adobeTargetActivityResponse,
      unparsedPageEntry: pageEntry,
      forceControlExperience
    });

    // create the adobeTargetActivity object to be sent to the client
    adobeTargetActivity = isIAdobeTargetActivityError(adobeTargetActivityResponse)
      ? { ...adobeTargetActivityResponse }
      : { ...adobeTargetActivityResponse, supplementalDataId };
  }

  const pageProps = await FPage([parsedPageEntry], getParsedSection);

  // This can possibly go into the shared server side props but leaving it here for now.
  await offerContentManager.load(correlationId, appServerSideProps.cookie);

  const pageDataWithOffers = offerContentManager.applySectionOverrides(pageProps, pagePath, correlationId);
  Object.assign(pageProps, pageDataWithOffers);

  let relatedArticles: Entry<any>[] = [];

  if (doesPageLinkToBlogArticle([parsedPageEntry] as Entry<any>[])) {
    const articleData: Entry<any> = parsedPageEntry?.fields.page ?? parsedPageEntry?.fields.blogArticle;

    pageProps.blogArticle = await FArticleBlog(articleData, getParsedSection);
    relatedArticles = await getRelatedArticles([parsedPageEntry], contentfulSettings);
  }

  return {
    pageData: {
      ...pageProps,
      relatedArticles,
      adobeTargetActivity
    }
  };
};

export const getServerSideProps = withServerSideAppProps<IDynamicPageProps>(getServerSidePageProps);
