export enum SERVICE_ADD_TEST_IDS {
  ROOT = 'service-add',
  GET_A_SIM_BUTTON = 'get-a-sim-button',
  ACTIVATE_SIM_BUTTON = 'active-sim-button',
  COMPLETE_SETUP_BUTTON = 'draft-primary-button'
}

export enum SECONDARY_TILE_TEST_IDS {
  ROOT = 'secondary-tile-root',
  LINK1 = 'secondary-tile-link1',
  LINK2 = 'secondary-tile-link2',
  CALLOUT = 'secondary-tile-callout'
}

export enum ACTIVATION_CTA_TEST_ID {
  CONFIRM = 'confirm-button',
  CANCEL = 'cancel-button',
  BACK = 'back-button'
}

export enum MODAL_TEST_IDS {
  ROOT = 'modal',
  CLOSE_BUTTON = 'modal-close-button',
  CONTENTS = 'modal-contents',
  DIALOG_BOX = 'modal-body',
  DEFAULT_PRIMARY_BUTTON = 'default-primary-button',
  DEFAULT_SECONDARY_BUTTON = 'default-secondary-button'
}

export enum ACTIVATE_PLAN_STEP {
  PLAN_STEP = 'activate-mobile-plan-cta',
  PLAN_STEP_CTA = 'progress-indicator-step-cta',
  PLAN_STEP_TITLE = 'progress-indicator-step-title'
}

export enum ACTIVATE_MOBILE_NUMBER_STEP {
  MOBILE_NUMBER_STEP = 'activate-mobile-number-cta',
  MOBILE_NUMBER_STEP_CTA = 'progress-indicator-step-cta',
  MOBILE_NUMBER_STEP_TITLE = 'progress-indicator-step-title'
}

export enum ARTICLE_BLOG_VIEW_TEST_ID {
  SECTION_ARTICLE_HEADER = 'blog-article-view-section-article-header',
  SECTION_BODY = 'blog-article-view-section-body',
  RELATED_ARTICLES = 'blog-article-view-related-articles'
}

export enum MODEM_PAYMENT_SELECTION_TEST_ID {
  SECTION = 'MODEM_PAYMENT_SELECTION_SECTION',
  ERROR_ALERT = 'MODEM_PAYMENT_SELECTION_ERROR_ALERT',
  TOP_CONTENT = 'MODEM_PAYMENT_SELECTION_TOP_CONTENT',
  RADIO_SELECTORS = 'MODEM_PAYMENT_SELECTION_RADIO_SELECTORS',
  OPTION_MONTHLY = 'MODEM_PAYMENT_SELECTION_OPTION_MONTHLY',
  OPTION_UPFRONT = 'MODEM_PAYMENT_SELECTION_OPTION_UPFRONT',
  OPTION_BYO = 'MODEM_PAYMENT_SELECTION_OPTION_BYO',
  OPTION_FREE = 'MODEM_PAYMENT_SELECTION_OPTION_FREE',
  POST_OPTIONS_CONTENT = 'MODEM_PAYMENT_SELECTION_POST_OPTIONS_CONTENT',
  BYO_AGREEMENT_TERMS = 'MODEM_PAYMENT_SELECTION_BYO_AGREEMENT_TERMS',
  CHOICE_CHIP_NO = 'ChoiceChipset-choice-0',
  CHOICE_CHIP_YES = 'ChoiceChipset-choice-1',
  NEXT_BUTTON = 'section-internet-plans-display-root__button-next',
  PLAN_SELECTION_ERROR = 'section-internet-plans-display-root__error-alert'
}

export enum SERVICE_DETECTED_SELECTION_TEST_ID {
  INTRO_TEXT = 'SERVICE_DETECTED_SELECTION_INTRO_TEXT',
  AUTHORITY_TEXT = 'SERVICE_DETECTED_SELECTION_AUTHORITY_TEXT',
  ALERT_TEXT = 'SERVICE_DETECTED_SELECTION_ALERT_TEXT',
  OPTION_ONE = 'SERVICE_DETECTED_SELECTION_OPTION_ONE',
  OPTION_TWO = 'SERVICE_DETECTED_SELECTION_OPTION_TWO'
}

export enum NDC_SELECTION_TEST_ID {
  SECTION = 'NDC_SELECTION_SECTION',
  ERROR_ALERT = 'ndc-payment-optionError',
  TOP_CONTENT = 'NDC_SELECTION_TOP_CONTENT',
  OPTION_MONTHLY = 'NDC_SELECTION_OPTION_MONTHLY',
  OPTION_UPFRONT = 'NDC_SELECTION_OPTION_UPFRONT',
  POST_OPTIONS_CONTENT = 'NDC_SELECTION_POST_OPTIONS_CONTENT'
}

export enum MOBILE_CAPTURE_TEST_ID {
  FORM = 'MOBILE_CAPTURE_FORM',
  INTRO = 'MOBILE_CAPTURE_INTRO',
  SECTION = 'MOBILE_NUMBER_SELECTION_SECTION',
  ERROR_ALERT = 'MOBILE_NUMBER_SELECTION_ERROR_ALERT',
  OPTION_EXISTING = 'MOBILE_NUMBER_SELECTION_OPTION_EXISTING',
  OPTION_NEW = 'MOBILE_NUMBER_SELECTION_OPTION_NEW',
  SUBMIT_BTN = 'MOBILE_CAPTURE_SUBMIT_BTN',
  BACK_BTN = 'MOBILE_CAPTURE_BACK_BTN'
}

export enum MOBILE_VERIFY_TEST_ID {
  FORM = 'MOBILE_VERIFY_FORM',
  ALERT = 'MOBILE_VERIFY_ALERT',
  SECTION = 'NBN_MOBILE_NUMBER_VERIFY_SECTION',
  ERROR_ALERT = 'MOBILE_NUMBER_SELECTION_ERROR_ALERT',
  VERIFY_INPUT = 'NBN_MOBILE_NUMBER_VERIFY_INPUT',
  SUBMIT_BTN = 'MOBILE_VERIFY_SUBMIT_BTN',
  BACK_BTN = 'MOBILE_VERIFY_BACK_BTN'
}
export enum TERMS_CONDITIONS_TEST_ID {
  FORM = 'TERMS_CONDITIONS_FORM',
  ALERT = 'TERMS_CONDITIONS_ALERT',
  SMS = 'TERMS_CONDITIONS_SMS',
  EMAIL = 'TERMS_CONDITIONS_EMAIL',
  ACKNOWLEDGEMENT = 'TERMS_CONDITIONS_ACKNOWLEDGEMENT',
  BYO_CHECKBOX = 'TERMS_CONDITIONS_BYO_CHECKBOX',
  SERVER_ERROR = 'TERMS_CONDITIONS_SERVER_ERROR',
  SUBMIT_BTN = 'TERMS_CONDITIONS_SUBMIT_BTN',
  BACK_BTN = 'TERMS_CONDITIONS_BACK_BTN',
  ACCORDION = 'TERMS_CONDITIONS_ACCORDION',
  BILLING = 'TERMS_CONDITIONS_BILLING'
}

export enum NBN_ORDER_SUMMARY_TEST_ID {
  ADDRESS_UPDATE_BTN = 'ADDRESS_ROW-button',
  PLAN_UPDATE_BTN = 'PLAN_ROW-button',
  PROMO_CODE_BTN = 'CAPTURE_PROMO_BUTTON',
  ORDER_SUMMARY = 'SECTION_ORDER_SUMMARY'
}

export const NBN_PAGE_TEST_IDS = {
  WELCOME_ALERT: 'WELCOME_ALERT',
  ERROR_ALERT: 'ERROR_ALERT',
  NBN_JOIN_PAGE: 'NBN_JOIN_PAGE',
  CHECKBOX_ACCEPT_CONDITIONS: 'CHECKBOX_ACCEPT_CONDITIONS',
  CHECKBOX_ACKNOWLEDGE_BYO: 'CHECKBOX_ACKNOWLEDGE_BYO',
  ACCORDION_ROW: 'ACCORDION_ROW',
  BILLING_CONTENT: 'BILLING_CONTENT',
  SECTION_INNER_SECTION: 'section-inner-section',
  NBN_JOIN_PAYMENT_PAGE: 'NBN_JOIN_PAYMENT_PAGE',
  NBN_SERVICE_DETECTED_PAGE: 'NBN_SERVICE_DETECTED_PAGE',
  PAYMENT_METHOD_LIST: 'PAYMENT_METHOD_LIST',
  NBN_JOIN_MOBILE_NUMBER_PAGE: 'NBN_JOIN_MOBILE_NUMBER_PAGE',
  MODEM_DELIVERY_PAGE: 'MODEM_DELIVERY_PAGE',
  CONNECTION_DATE_PAGE: 'CONNECTION_DATE_PAGE',
  NBN_CONFIRMATION_PAGE_NO_BOOKING_DEFAULT: 'NBN_CONFIRMATION_PAGE_NO_BOOKING_DEFAULT',
  NBN_CONFIRMATION_PAGE_NO_BOOKING_HFC: 'NBN_CONFIRMATION_PAGE_NO_BOOKING_HFC',
  NBN_CONFIRMATION_PAGE_NO_BOOKING_CONNECT_OUTSTANDING: 'NBN_CONFIRMATION_PAGE_NO_BOOKING_CONNECT_OUTSTANDING',
  NBN_CONFIRMATION_PAGE_BOOKING_CLASSIC: 'NBN_CONFIRMATION_PAGE_BOOKING_CLASSIC',
  NBN_CONFIRMATION_PAGE_BOOKING_NEW_STACK: 'NBN_CONFIRMATION_PAGE_BOOKING_NEW_STACK',
  NBN_CONFIRMATION_PAGE_APPOINTMENT_BOOKING_ISSUE: 'NBN_CONFIRMATION_PAGE_APPOINTMENT_BOOKING_ISSUE',
  ORDER_CONFIRMATION_APPOINTMENT_ARRANGEMENT_REQUIRED: 'ORDER_CONFIRMATION_APPOINTMENT_ARRANGEMENT_REQUIRED',
  NBN_INVALID_ORDER: 'NBN_INVALID_ORDER',
  NBN_PENDING_SERVICE: 'NBN_PENDING_SERVICE',
  NBN_ACTIVE_SERVICE: 'NBN_ACTIVE_SERVICE'
};
