import { COLOURS } from '@belong/themes';
import styled, { css } from 'styled-components';
import UICard from '../../styles/UICard';

const GUTTER = '1.7rem';

const containerStyles = css`
  display: flex;
  flex-direction: column;

  > * {
    box-shadow: none !important;

    &:hover,
    &:focus {
      z-index: 1;
    }
  }
`;

export const HeroContainer = styled(UICard)`
  ${containerStyles};
  height: 100%;
`;

export const ListContainer = styled(UICard)`
  ${containerStyles};

  > * {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: ${GUTTER};
      height: 1px;
      width: calc(100% - (${GUTTER} * 2));
      text-align: center;
      background-color: ${COLOURS.GREY_200};
    }

    // no divider on first-child
    &:first-child:before {
      height: 0;
    }

    &:hover {
      // remove divider for self and adjacent sibling on hover
      &:before,
      + *:before {
        height: 0;
      }
    }
  }
`;
