import React from 'react';
import { Heading, Section, ArticleGroup, ButtonGroup, Button, ContentFormatter, Stack } from '@belong/ui-components';
import { IButton } from '@belong/types';
import { BUTTON_VARIANTS } from '@belong/themes';
import { ISectionArticleGroup } from './model';

export const TEST_ID = {
  CONTAINER: 'section-article-group',
  TITLE: 'article-group-title',
  LINKS: 'article-group-links'
};

const buttonPrecedence = Object.values(BUTTON_VARIANTS);
const buttonSorter = (a, b) => (buttonPrecedence.indexOf(a.variant) > buttonPrecedence.indexOf(b.variant) ? 1 : -1);
const buttonWidths: Pick<IButton, 'width'> = {
  width: {
    xs: 'full',
    md: 'default'
  }
};

const SectionArticleGroup: React.FC<ISectionArticleGroup> = props => {
  const { id, identifier, title, sectionStyles, links, ...articleProps } = props;
  links.sort(buttonSorter);
  const hasLinks = !!links.length;

  return (
    <Section data-contentid={id} data-testid={TEST_ID.CONTAINER} data-identifier={identifier} {...sectionStyles}>
      <Stack space={{ xs: 'xsmall', md: 'medium', lg: 'large' }}>
        {title && (
          <ContentFormatter alignment="center">
            <Heading data-testid={TEST_ID.TITLE} as="h3">
              {title}
            </Heading>
          </ContentFormatter>
        )}
        <ArticleGroup {...articleProps} />
        {hasLinks && (
          <ButtonGroup
            data-testid={TEST_ID.LINKS}
            hasControl={{ xs: ['column', 'center', false], lg: ['row', 'center', true] }}
          >
            {links.map(({ label, ...btnProps }) => (
              <Button key={label} {...btnProps} {...buttonWidths}>
                {label}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Stack>
    </Section>
  );
};

export default SectionArticleGroup;
