import { COLOURS, SHADOWS, media } from '@belong/themes';
import styled, { css } from 'styled-components';
import GenericButton from '../../actions/GenericButton';

const GUTTER = '1.6rem';
const TRANSITION = {
  over: `
    transform: scale(1.05);
    transition: transform 0.2s ease-out;
  `,
  out: 'transition: transform 0.3s ease-out;'
};

export const IMAGE_SIZE = {
  slim: {
    xs: { w: 145, h: 82 },
    md: { w: 200, h: 112 }
  },
  hero: {
    xs: { w: 343, h: 200 },
    md: { w: 610, h: 340 }
  }
};

const getObjectFit = ({ contentType }) => (contentType === 'svg' ? 'contain' : 'cover');

export const ImageWrapper = styled.div<{ contentType: string }>`
  img,
  picture {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: ${getObjectFit};
  }
`;

type TileContainer = {
  isHero?: boolean;
};

export const SlimContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: ${GUTTER};

  > * {
    overflow: hidden;
    width: 50%;
  }

  > *:first-child {
    ${media('xl')`width: 60%;`}
  }

  > *:last-child {
    ${media('xl')`width: 40%;`}
  }

  img {
    ${TRANSITION.out};
  }

  p:first-child {
    margin-bottom: 0.8rem;
  }

  ${media('xs')`
    min-height: 13.2rem;
  `}
  ${media('xl')`
    min-height: 14.4rem;
  `}
`;

export const HeroContainer = css`
  ${SlimContainer};
  flex-direction: column-reverse;
  padding: 0;

  > * {
    width: 100%;
  }

  > *:first-child {
    ${media('xl')`width: 100%;`}
  }

  > *:last-child {
    ${media('xl')`width: 100%;`}
  }

  > div:first-child {
    padding: 2.4rem ${GUTTER};
  }
`;

export const Container = styled.div<TileContainer>`
  ${({ isHero }) => (isHero ? HeroContainer : SlimContainer)};
`;

export const Content = styled.div`
  &:not(:last-child) {
    margin-right: ${GUTTER};
  }
  overflow-wrap: break-word;
`;

export const Button = styled(GenericButton)<{ isHero: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  box-shadow: ${SHADOWS.AMBIENT_LIGHT};
  background-color: ${COLOURS.WHITE};
  cursor: pointer;

  &:hover {
    background-color: ${COLOURS.GREY_100};
    box-shadow: ${SHADOWS.KEY_LIGHT};

    img {
      ${TRANSITION.over};
    }

    h3,
    h4,
    h5,
    h6 {
      text-decoration: underline;
      color: ${COLOURS.DARK_BLUE};
    }
  }
`;

export const SkeletonContainer = styled.div<{ isHero?: boolean; height?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ height }) => (height ? `${height}px;` : 'auto')};
  flex-grow: 1;

  ${({ isHero }) => {
    if (!isHero) {
      return `
      &:last-child:not(:first-child) {
        margin-left: ${GUTTER};
      }
    `;
    }
    return `
      &:last-child {
        padding: 0;
      }
    `;
  }}
`;
