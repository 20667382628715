import React from 'react';
import { logger } from '@belong/logging/logger';
import { IContentfulSection } from '@belong/types';
import globalSections from '@belong/ui-sections/bootstrap';

import * as views from '../sections/views';

const ContentfulSection: React.FC<IContentfulSection> = props => {
  const sectionId = props.contentType;

  if (!sectionId) {
    logger.error(
      `Section with ID "${props.id}" was rendered without a content type. Does your section model have a \`contentType\` property?`
    );
    return null;
  }

  // check for local section first as some import and decorate global sections
  if (sectionId in views) {
    const Component = views[sectionId];
    return <Component {...props} />;
  }

  if (sectionId in globalSections) {
    const Component = globalSections[sectionId];
    return <Component {...props} />;
  }

  logger.error(`No matching component for "${props.contentType}"`);

  return null;
};

export default ContentfulSection;
