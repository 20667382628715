import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { COLOURS } from '@belong/themes';
import { ISkeletonProps } from './Skeleton.types';
import { SkeletonLine } from './Skeleton.styles';

export const Skeleton: React.FC<ISkeletonProps> = ({
  count = 1,
  circle = false,
  width,
  height,
  'data-testid': testId = 'skeleton-line',
  Wrapper,
  id,
  ...otherProps
}: ISkeletonProps) => {
  const nodes = Array.from(new Array(count), (_, idx) => {
    const realIDBase = id || uuidv4();
    const body = (
      <SkeletonLine
        key={`${realIDBase}${idx}`}
        data-testid={testId}
        width={width}
        height={height}
        color={COLOURS.GREY_300}
        highlightColor={COLOURS.GREY_200}
        circle={circle}
        {...otherProps}
      />
    );

    if (Wrapper) {
      return <Wrapper key={realIDBase}>{body}</Wrapper>;
    }

    return <React.Fragment key={realIDBase}>{body}</React.Fragment>;
  });

  return <>{nodes}</>;
};

Skeleton.displayName = 'Skeleton';

export default Skeleton;
