import { IAdobeTargetActivityResponse, isIAdobeTargetActivityError } from '@belong/adobe-target';

export const getABTestAnalyticsData = (activity?: IAdobeTargetActivityResponse): { [key: string]: any } | undefined => {
  if (activity && (isIAdobeTargetActivityError(activity) || activity.status)) {
    return {
      ...activity
    };
  }
  return {};
};
