import { getConfig } from '@belong/configs/next/config';

// we can add more configuration here for different environments if requied
const adobeTargetConfig = getConfig().publicRuntimeConfig.adobeTarget;

export const CONFIG = {
  client: adobeTargetConfig?.client,
  organizationId: adobeTargetConfig?.organizationId,
  timeout: adobeTargetConfig?.timeout && parseInt(adobeTargetConfig.timeout, 10),
  serverDomain: adobeTargetConfig?.serverDomain,
  propertyToken: adobeTargetConfig?.propertyToken,
  adobeCookieName: 'AMCV_98DC73AE52E13F1E0A490D4C%40AdobeOrg',
  trackingServer: 'infos.belong.com.au',
  contentfulGenericModelId: 'adobeGenericBlock'
};
