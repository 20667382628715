import React, { FunctionComponent } from 'react';

import { Column, Columns, IColumnsProps } from '../../ui';
import ArticleTile from '../ArticleTile';
import { Skeleton } from './ArticleGroup.skeleton';
import * as Styles from './ArticleGroup.styles';
import { IArticleGroup, ArticleGroupLayout } from './types';

export const TEST_ID = {
  ARTICLE_GROUP: 'article-group',
  COLUMN_HERO: 'column-hero',
  COLUMN_LIST: 'column-list'
};

export const ArticleGroup: FunctionComponent<IArticleGroup> = ({ articles, layout, isLoading }: IArticleGroup) => {
  const reverse: IColumnsProps['reverse'] = layout === ArticleGroupLayout.LEFT ? 'never' : 'always';

  if (isLoading) {
    return <Skeleton />;
  }

  if (!articles.length) {
    return null;
  }

  return (
    <Columns
      data-testid={TEST_ID.ARTICLE_GROUP}
      alignY="stretch"
      reverse={reverse}
      space={{ xs: 'medium', md: 'large', lg: 'xlarge' }}
      collapseBelow="lg"
    >
      <Column width={{ lg: '1/2' }}>
        <Styles.HeroContainer data-testid={TEST_ID.COLUMN_HERO} hasShadow>
          <ArticleTile {...articles[0]} isHero />
        </Styles.HeroContainer>
      </Column>
      <Column width={{ lg: '1/2' }}>
        <Styles.ListContainer data-testid={TEST_ID.COLUMN_LIST} hasShadow>
          {articles.slice(1).map(article => (
            <ArticleTile key={article.title} {...article} />
          ))}
        </Styles.ListContainer>
      </Column>
    </Columns>
  );
};

ArticleGroup.displayName = 'ArticleGroup';
