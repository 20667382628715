import { logger } from '@belong/logging/logger';

const getTransformer = async (id: string): Promise<any> => {
  try {
    return import(/* webpackChunkName: "[request]" */ `./sections/${id}`).then(module => module.default.model);
  } catch (e) {
    logger.error(`Could not dynamically import module ${id}`);
    return null;
  }
};

/**
 * Section data transformer - converts Contentful data into props for a specific section
 * @param section - the contentful data to be transformed
 */
const createSectionModel = async (section: any): Promise<any> => {
  const contentType = section?.sys?.contentType?.sys?.id;

  if (!contentType) {
    throw new Error('Missing value for ContentType');
  }

  try {
    const transformer = await getTransformer(contentType);
    return transformer(section);
  } catch (err) {
    // Couldn't load section or data transformation failure
    logger.error(`Error processing '${contentType}': ${err.message}`);
    return {};
  }
};

export default createSectionModel;
