import { Entry } from 'contentful';
import { TCalloutVariant } from '../ui-components/Callout.types';
import { IRichText, IImage } from '../ui';
import { FlairsKey } from '../theme';
import { IIcon } from '../icons';

export interface IContentfulDateOfBirthInput {
  sys: {
    [key: string]: any;
  };
  fields: {
    identifier: string;
    label: string;
    description?: string;
    errorMessageRequired: string;
    errorMessageInvalidDateFormat: string;
    errorMessageUnderageUser: string;
    errorMessageMaxDateOfBirthYear: string;
  };
}

export interface ICMSDateOfBirthInput {
  label: string;
  description?: string;
  errorMessages: {
    required: string;
    dateFormat: string;
    underageUser: string;
    maxDateOfBirthYear: string;
  };
}

export interface IContentfulMobileNumberInput {
  sys: {
    [key: string]: any;
  };
  fields: {
    identifier: string;
    label: string;
    description?: string;
    errorMessageRequired: string;
    errorMessageInvalidMobileNumber: string;
  };
}
export interface IMobileNumberInput {
  label: string;
  description?: string;
  errorMessages: {
    required: string;
    invalid: string;
  };
}

export interface IContentfulTextInput {
  sys: {
    [key: string]: any;
  };
  fields: {
    identifier: string;
    label: string;
    description?: string;
    errorMessageRequired: string;
    errorMessageInvalid: string;
  };
}

export interface ITextInput {
  label: string;
  description?: string;
  errorMessages: {
    required: string;
    invalid: string;
  };
}

export interface IContentfulEmailAddressInput {
  sys: {
    [key: string]: any;
  };
  fields: {
    identifier: string;
    label: string;
    description?: string;
    errorMessageRequired: string;
    errorMessageInvalidEmail: string;
  };
}

export interface IEmailAddressInput {
  label: string;
  description?: string;
  errorMessages: {
    required: string;
    invalid: string;
  };
}

export interface IMoleculeCallout {
  id: string;
  contentType: 'moleculeCallout';
  colour: FlairsKey;
  icon?: IIcon;
  title?: IRichText;
  description?: IRichText;
  backgroundImage?: IImage;
  available?: boolean;
  variant?: TCalloutVariant;
}

export interface IOfferAlert {
  id: string;
  code: string;
  description?: IRichText;
  callout: IMoleculeCallout;
}

export interface IOfferTemplate {
  code: string;
  colour: FlairsKey;
  title?: IRichText;
  description?: IRichText;
}

export interface IElementDataBalance {
  id: string;
  contentType: 'elementDataBalance';
  dataSummary: IRichText;
  outOfDataSummary: IRichText;
  endpointDetails?: IRichText;
}

export type ImageFormat = 'jpg' | 'png' | 'svg';

export function isEntry<T = any>(obj: any): obj is Entry<T> {
  return obj.sys && obj.fields;
}
