import { logger } from '@belong/logging/logger';
import { Document } from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Entry } from 'contentful';

export type FlexibleComponentMap = Map<string, { factory: (data: Entry<any>) => any }>;

interface IMoleculeRichTextEntry extends Entry<any> {
  fields: {
    key: string;
    text: Document;
  };
}

const isRichTextEntry = (entry: Entry<any>): entry is IMoleculeRichTextEntry => {
  return entry.sys.contentType.sys.id === 'moleculeRichText';
};

const isPlainTextEntry = (entry: Entry<any>): entry is Entry<{ key: string; text: string }> => {
  return entry.sys.contentType.sys.id === 'moleculeText';
};

// this is a convention used by producers to have an entry present (with the right key)
// but have it not be used in the page.
export const MAGIC_KEY_FOR_PUBLISHED_BUT_UNUSED_FIELDS = '{not in use}';

export const parseFlexibleContent = (
  cmsData: Entry<{ key?: string }>[],
  factoryMap?: FlexibleComponentMap
): Record<string, any> => {
  if (cmsData.length === 0 || !factoryMap) {
    return {};
  }

  const cmsEntry = {};

  cmsData.forEach(entry => {
    const contentType = entry.sys.contentType.sys.id;
    const factory = factoryMap.get(contentType)?.factory;

    if (!factory) {
      return;
    }

    if (!entry.fields.key) {
      logger.warn(`Flexible content entry ${contentType} does not have a key`);
      return;
    }

    if (isRichTextEntry(entry)) {
      const content = documentToPlainTextString(entry.fields.text);
      if (content.trim() === MAGIC_KEY_FOR_PUBLISHED_BUT_UNUSED_FIELDS) {
        return; // prevent this from being added to the page
      }
    }

    if (isPlainTextEntry(entry)) {
      if (entry.fields.text.trim() === MAGIC_KEY_FOR_PUBLISHED_BUT_UNUSED_FIELDS) {
        return; // prevent this from being added to the page
      }
    }

    try {
      cmsEntry[entry.fields.key] = factory(entry);
    } catch (e) {
      logger.error(`Error parsing flexible content ${entry.fields.key} for ${contentType}`, e);
    }
  });

  return cmsEntry;
};
